import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { DictString } from '../../../models';
import { KeyboardService } from '../../keyboard/services/keyboard.service';
import { ControlBaseComponent } from '../base/control-base.component';
import { ControlInput1Component } from '../input1/control-input1.component';
import { ControlInput1Module } from '../input1/control-input1.module';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { RuntimeLayoutControl } from 'src/app/shared/models/memorypack/RuntimeLayoutControl';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';


@Component({
  standalone: true,
  imports: [
    SharedModule,
    ControlInput1Module,
  ],
  selector: 'lc-control-dimension1',
  templateUrl: 'control-dimension1.component.html',
  styleUrls: ['./control-dimension1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlDimension1Component extends ControlBaseComponent implements OnInit, OnDestroy {

  readonly thisDocument: Document = document;

  @ViewChild('controlWidthComponent', { static: false }) controlWidthComponent: ControlInput1Component;
  @ViewChild('controlHeightComponent', { static: false }) controlHeightComponent: ControlInput1Component;
  @ViewChild('controlLengthComponent', { static: false }) controlLengthComponent: ControlInput1Component;

  theme: string;

  currentScaleSend: any;
  isManual: boolean;
  staticControlWidth: any;
  staticControlHeight: any;
  staticControlLength: any;
  unitOfMeasure: string;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private keyboardService: KeyboardService,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.layoutScreen.forwardButton = true;

    this.unitOfMeasure = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UoM');

    this.refresh();
  }

  refresh() {
    this.isManual = true;

    this.staticControlWidth = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Width') + (this.unitOfMeasure ? ' (' + this.unitOfMeasure + ')' : '') + ':',
      scannerEnabledType: (this.layoutControl as RuntimeLayoutControl).scannerEnabledType,
      keyboardType: KeyboardType.Numeric,
      defaultTextBoxValue: undefined
    };

    this.staticControlHeight = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Height') + (this.unitOfMeasure ? ' (' + this.unitOfMeasure + ')' : '')  + ':',
      scannerEnabledType: (this.layoutControl as RuntimeLayoutControl).scannerEnabledType,
      keyboardType: KeyboardType.Numeric,
      defaultTextBoxValue: undefined
    };

    this.staticControlLength = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Depth') + (this.unitOfMeasure ? ' (' + this.unitOfMeasure + ')' : '')  + ':',
      scannerEnabledType: (this.layoutControl as RuntimeLayoutControl).scannerEnabledType,
      keyboardType: KeyboardType.Numeric,
      defaultTextBoxValue: undefined
    };
    this.cdr.markForCheck();
  }

  forwardButtonOverride(): boolean {
    if (this.controlWidthComponent?.inputRef?.nativeElement === this.keyboardService.getActiveElement()) {
      const nextInput = this.controlHeightComponent.inputRef.nativeElement;
      nextInput.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      nextInput.select();
      nextInput.focus();
      this.keyboardService.setActiveElement(
        nextInput,
        this.controlHeightComponent.onSoftwareKeyPress.bind(this.controlHeightComponent),
      );
      this.cdr.markForCheck();

      return true;
    } else if (this.controlHeightComponent?.inputRef?.nativeElement === this.keyboardService.getActiveElement()) {
      const nextInput = this.controlLengthComponent.inputRef.nativeElement;
      nextInput.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      nextInput.select();
      nextInput.focus();
      this.keyboardService.setActiveElement(
        nextInput,
        this.controlHeightComponent.onSoftwareKeyPress.bind(this.controlHeightComponent),
      );
      this.cdr.markForCheck();

      return true;
    }

    const width = RuntimeLayoutUtils.parseLayoutValue(this.controlWidthComponent?.getControlContext()?.get('TextBox'));
    const height = RuntimeLayoutUtils.parseLayoutValue(this.controlHeightComponent?.getControlContext()?.get('TextBox'));
    const length = RuntimeLayoutUtils.parseLayoutValue(this.controlLengthComponent?.getControlContext()?.get('TextBox'));

    if (!width && !height && !length) {
      this.otherPortHandler('NoData');
      return true;
    } else if (width && height && length) {
      this.otherPortHandler('Dimensions');
      return true;
    }

    return false;
  }

  private otherPortHandler(portName: string) {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();

    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    const width = RuntimeLayoutUtils.parseLayoutValue(this.controlWidthComponent?.getControlContext()?.get('TextBox'));
    const height = RuntimeLayoutUtils.parseLayoutValue(this.controlHeightComponent?.getControlContext()?.get('TextBox'));
    const length = RuntimeLayoutUtils.parseLayoutValue(this.controlLengthComponent?.getControlContext()?.get('TextBox'));

    context.set('WidthInMillimeter', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(width),
      valueTypeId: RuntimeLayoutValueType.Double
    }));

    context.set('HeightInMillimeter', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(height),
      valueTypeId: RuntimeLayoutValueType.Double
    }));

    context.set('LengthInMillimeter', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(length),
      valueTypeId: RuntimeLayoutValueType.Double
    }));

    context.set('Manual', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.isManual),
      valueTypeId: RuntimeLayoutValueType.Bool
    }));

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

  tryParseDimensionCipherlabScan(controlComponent: any) {
    const possibleDimensionScan = RuntimeLayoutUtils.parseLayoutValue(controlComponent?.getControlContext()?.get('TextBox'));
    if (
      (
        possibleDimensionScan.indexOf('<3D>') === 0
        || possibleDimensionScan.indexOf('<2D>') === 0
      ) && possibleDimensionScan.indexOf('\r\n') > 0
    ) {
      // we got a dimension!
      const uom = possibleDimensionScan.substring(23, 27).trim().toLowerCase();
      const uomMultiplier = uom === 'cm' ? 10 : uom === 'inch' ? 25.4 : 1;
      const width = parseFloat(possibleDimensionScan.substring(26, 31).trim() || '0.0');
      const height = parseFloat(possibleDimensionScan.substring(32, 37).trim() || '0.0');
      const length = parseFloat(possibleDimensionScan.substring(38, 43).trim() || '0.0');
      this.controlWidthComponent.value = '' + (width * uomMultiplier);
      this.controlHeightComponent.value = '' + (height * uomMultiplier);
      this.controlLengthComponent.value = '' + (length * uomMultiplier);
      this.isManual = false;
    } else {
      controlComponent.value = possibleDimensionScan;
      this.isManual = true;
    }
    this.cdr.markForCheck();
  }

}

