import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { themes } from './themes';
import { DeviceEnrollment3ClientSkin } from '../../models/memorypack/DeviceEnrollment3ClientSkin';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  currentThemeKey: string;
  currentFontSize: string;

  extras: any = {};
  themesMap: { [key: string]: any } = JSON.parse(JSON.stringify(themes));

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  getMenuIcon() {
    return this.themesMap[this.currentThemeKey]?.menuIcon;
  }

  overrideThemesWithClientSkins(clientSkins: DeviceEnrollment3ClientSkin[]) {
    this.themesMap = JSON.parse(JSON.stringify(themes)); // default to the defaults (in case they have changed)...

    for (const clientSkin of clientSkins || []) {
      const clientSkinStyle = (clientSkin.style || '').toLowerCase();
      if (clientSkinStyle !== 'dark' && clientSkinStyle !== 'light') continue;

      Object.assign(this.themesMap[clientSkinStyle], {
        primary: clientSkin.defaultHeadlineBackgroundColor || undefined,
        light: clientSkin.defaultHeadlineFontColor || undefined,

        background: clientSkin.workareaBackgroundColor || undefined,
        font: clientSkin.workareaFontColor || undefined,

        menuIcon: clientSkin.menuIcon || undefined,
      });

      // clientSkin.headlineLogotype
      // clientSkin.workareaBackgroundPicture;
      // clientSkin.logonLogotype
    }

    this.reapplyCurrentTheme();
  }

  private reapplyCurrentTheme() {
    this.setTheme(this.currentThemeKey, this.currentFontSize);
  }

  // Override all global variables with a new theme
  setTheme(themeKey: string, fontSize: string) {
    this.currentThemeKey = themeKey;
    this.currentFontSize = fontSize;

    this.document.documentElement.classList.remove('theme-light');
    this.document.documentElement.classList.remove('theme-dark');
    this.document.documentElement.classList.remove('font-smaller');
    this.document.documentElement.classList.remove('font-small');
    this.document.documentElement.classList.remove('font-medium');
    this.document.documentElement.classList.remove('font-big');
    this.document.documentElement.classList.remove('font-huge');

    if (themeKey === 'system') {
      themeKey = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      // to watch for changes...
      // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      //   themeKey = event.matches ? "dark" : "light";
      // });
    }

    this.document.documentElement.classList.add(`theme-${themeKey}`);
    this.document.documentElement.classList.add(`font-${fontSize}`);

    const cssText = this.CSSTextGenerator(themeKey);
    this.document.documentElement.style.cssText = cssText;
  }

  // Define a single CSS variable
  setVariable(name: string, value: string) {
    delete this.extras[name];
    if (value) {
      this.extras[name] = value;
      this.document.documentElement.style.setProperty(name, value);
    } else {
      this.document.documentElement.style.removeProperty(name);
    }
  }

  private CSSTextGenerator(themeKey: string) {
    const theme = this.themesMap[themeKey] || this.themesMap['light'];

    let cssText = `
      --lc-primary-color: ${theme.primary};
      --lc-secondary-color: ${theme.secondary};
      --lc-tertiary-color: ${theme.tertiary};
      --lc-success-color: ${theme.success};
      --lc-warning-color: ${theme.warning};
      --lc-danger-color: ${theme.danger};
      --lc-light-color: ${theme.light};
      --lc-dark-color: ${theme.dark};
      --lc-background-color: ${theme.background};
      --lc-font-color: ${theme.font};
    `;

    for (const key of Object.keys(this.extras)) {
      cssText += `${key}: ${this.extras[key]}`;
    }

    return cssText;
  }

}
