import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { from } from 'rxjs';
import { MobileEngineDebugPopover } from 'src/app/popovers/mobile-engine-debug/mobile-engine-debug.popover';
import { LayoutState } from '../../models/layout-state.model';
import { RuntimeLayout } from '../../models/memorypack/RuntimeLayout';
import { RuntimeLayoutScreen } from '../../models/memorypack/RuntimeLayoutScreen';
import { RuntimeLayoutSnapshot } from '../../models/memorypack/RuntimeLayoutSnapshot';
import { RuntimeLayoutUtils } from '../../models/runtime-layout/runtime-layout.utils';
import { AppService, DebugService, VibrationService } from '../../services';
import { TranslateService } from '../../services/app';
import { LayoutChangeActiveScreenService } from '../../services/protocol/layout-change-active-screen.service';
import { ThemeService } from '../../services/theme/theme.service';
import { SharedModule } from '../../shared.module';
import { LogUtils } from '../../utils';
import { BaseComponent } from '../base/base.component';



@Component({
  standalone: true,
  imports: [
    SharedModule,
    MobileEngineDebugPopover,
  ],
  selector: 'lc-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent {

  @Input() layoutSnapshot: RuntimeLayoutSnapshot;

  @Input() set layout (value: RuntimeLayout) {
    this._layout = value;

    this.refresh();
  }
  get layout(): RuntimeLayout {
    return this._layout;
  }
  private _layout: RuntimeLayout;

  @Input() hideMenu: boolean;
  @Output() layoutChange = new EventEmitter<RuntimeLayout>();

  activeScreen: RuntimeLayoutScreen;
  layoutScreens: RuntimeLayoutScreen[];

  environmentName: string;
  isBatteryLow: boolean;
  isConnectionToServerActive: boolean;
  isTabMenuOpened: boolean;
  menuIcon: string;
  mobileEngineLayoutState: LayoutState;
  mobileEngineOperationalMode: 'hybrid' | 'online';
  statusBarMessage: string;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private debugService: DebugService,
    private layoutChangeActiveScreenService: LayoutChangeActiveScreenService,
    private popoverCtrl: PopoverController,
    private themeService: ThemeService,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    super();

    this.layoutScreens = [];
    this.statusBarMessage = '';

    this.menuIcon = this.themeService.getMenuIcon() || 'menu';

    this.subscriptions.push(
      this.appService.isBatteryLow()
      .subscribe((isBatteryLow: boolean) => {
        this.isBatteryLow = isBatteryLow;
        this.cdr.markForCheck();
      }),
      this.appService.isConnectionToServerActive()
      .subscribe((isConnectionToServerActive: boolean) => {
        this.isConnectionToServerActive = isConnectionToServerActive;
        this.cdr.markForCheck();
      }),
      this.appService.listenToStatusBarMessageChanges()
      .subscribe((msg: string) => {
        if (msg) LogUtils.warn(msg);

        this.statusBarMessage = msg;
        this.cdr.markForCheck();
      }),
      this.appService.listenToMobileEngineLayoutState()
      .subscribe((layoutState: LayoutState) => {
        this.mobileEngineLayoutState = layoutState;
        this.cdr.markForCheck();
      }),
    );
  }

  refresh() {
    if (!this.layout) return;

    const windowAny: any = window;
    this.mobileEngineOperationalMode = windowAny.mobileEngine?.operationalMode;

    this.activeScreen = this.layout.layoutScreens.get(this.layout.activeScreen);

    this.layoutScreens = this.mapToArray(this.layout.layoutScreens);

    const de = this.appService.getDeviceEnrollment();
    this.environmentName = de?.colorOverrideShow ? de?.environmentName
    : undefined;

    this.cdr.markForCheck();
  }

 mapToArray<T>(map: Map<bigint, T>): T[] {
    if (!map) return [];

    return Array.from(map.values()).map((value: T) => {
      return value;
    });
  }

  setActiveScreen(objectId: bigint) {
    this.vibrationService.vibrate();

    if (this.layout) {
      this.layoutChangeActiveScreenService.trigger(this.layoutSnapshot, objectId)
      .subscribe({
        next: () => {

        },
        error: (error: any) => {
          LogUtils.error('setActiveScreen.trigger() error:', error);
        }
      });
    } else {
      LogUtils.error('setActiveScreen() with this.layout = undefined...how did this happen?');
      setTimeout(() => {
        this.debugService.sendAppStatus().subscribe();
      }, 1000);
    }

    this.isTabMenuOpened = false;

    this.refresh();
  }

  getScreenTitle(screen: RuntimeLayoutScreen) {
    let title = this.translateService.instant('Screen') + ' ' + (screen?.objectId || '0');
    if (!screen?.renderValues) return title;

    title = RuntimeLayoutUtils.parseRV(screen, 'Text', '');
    const extraText = RuntimeLayoutUtils.parseRV(screen, 'ExtraText', '');

    title = title + (extraText ? ' - ' + extraText : '');
    return title;
  }

  showMobileEngineDebugPopover(ev: Event) {
    ev.stopPropagation();

    this.vibrationService.vibrate();
    from(this.popoverCtrl.create({
      component: MobileEngineDebugPopover,
      componentProps: {
        mobileEngineLayoutState: this.mobileEngineLayoutState,
      },
      cssClass: `popover-mobile-engine-debug`,
      backdropDismiss: true,
      showBackdrop: true
    }))
    .subscribe((popover: HTMLIonPopoverElement) => {
      from(popover.onDidDismiss())
      .subscribe((result: OverlayEventDetail<string>) => {
        this.refresh();
      });

      popover.present();
    });
  }

}

