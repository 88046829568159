import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { from } from 'rxjs';
import { LayoutState } from 'src/app/shared/models/layout-state.model';
import { SharedModule } from 'src/app/shared/shared.module';



@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'lc-mobile-engine-debug-popover',
  templateUrl: 'mobile-engine-debug.popover.html',
  styleUrls: ['./mobile-engine-debug.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileEngineDebugPopover {

  mobileEngineLayoutState: LayoutState;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
  ) {
    const windowAny: any = window;
    this.mobileEngineLayoutState = this.navParams.get('mobileEngineLayoutState');
  }

  mobileEngineLayoutStateChange(online: boolean) {
    const windowAny: any = window;
    from(windowAny.mobileEngine.instance.invokeMethodAsync('DebugChangeOnlineStateAsync', online))
    .subscribe(() => {
      this.popoverCtrl.dismiss();
    });
  }

  mobileEngineRebootCloud() {
    const windowAny: any = window;
    from(windowAny.mobileEngine.instance.invokeMethodAsync('DebugRebootCloudAsync'))
    .subscribe(() => {
      this.popoverCtrl.dismiss();
    });
  }

}
