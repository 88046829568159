<!-- having listDefinition.style?.width set + listDefinition.style?.display == 'inline-grid' is what distinguishes between list / grid -->
<ion-content *ngIf="listDefinition"
  class="rfid-inventory-control"
  [style.opacity]="listReady ? 1 : 0"
  shadowCss
  shadowCustomCss=".inner-scroll.scroll-y { scroll-padding-top: 4px }">

  <div class="wrapper">
    <h2 *ngIf="layoutControl | parseRV:'ControlHeadlineEnabled'" style="padding-left: 4px;">
      {{ layoutControl | parseRV:'ControlHeadlineText' }}
    </h2>

    <div class="summary-panel flex-row space-evenly">
      <div id="counterUnknown" class="flex-col">
        <div class="value">
          {{ unknownBufferValues?.length || 0 }}
        </div>
        <div class="label flex-row gap-05 middle">
          <i class="fad fa-octagon-xmark"></i>
          <div>{{ 'Unknown' | translate }}</div>
        </div>
      </div>

      <div id="counterDeviation" class="flex-col">
        <div class="value">
          {{ deviationBufferValues?.length || 0 }}
        </div>
        <div class="label flex-row gap-05 middle">
          <i class="fad fa-octagon-exclamation"></i>
          <div>{{ 'Deviation' | translate }}</div>
        </div>
      </div>

      <div id="counterKnown" class="flex-col">
        <div class="value">
          {{ knownBufferValues?.length || 0 }}
        </div>
        <div class="label flex-row gap-05 middle">
          <i class="fad fa-octagon-check"></i>
          <div>{{ 'Found' | translate }}</div>
        </div>
      </div>

      <div class="flex-col">
        <div class="value">
          {{ totalInventoryTags || 0 }}
        </div>
        <div class="label flex-row gap-05 middle">
          <i class="fad fa-octagon"></i>
          <div>{{ 'Total' | translate }}</div>
        </div>
      </div>
    </div>

    <ion-list *ngIf="useLocations && locationOptions?.length"
      style="--ion-item-background: white">
      <ion-item>
        <ion-label>
          {{ 'Location' | translate }}
        </ion-label>
        <ion-select
          interface="popover"
          [(ngModel)]="locationGuidId">
          <ion-select-option *ngFor="let location of locationOptions"
            [value]="language.value">
            {{ language.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <div class="flex-1 {{ listDefinition.style?.width ? 'flex-row wrap' : 'flex-col gridster-gap' }}">
      <gridster *ngIf="headerDefinition?.items?.length"
        class="gridster background"
        [ngStyle]="listDefinition.header?.style"
        [style.maxHeight]="listDefinition.header?.style?.width ? listDefinition.header?.style?.height || listDefinition.header?.style?.minHeight : undefined"
        [style.minHeight]="listDefinition.header?.style?.minHeight ? getMinRowHeight(listDefinition.header?.style?.minHeight, '3.5rem') : ''"
        [style.flex]="!listDefinition.header?.style?.width ? 0 : undefined"
        [options]="gridsterOptions1">
        <gridster-item *ngFor="let item of headerDefinition?.items || []; let i = index;"
          class="gridster-item pointer"
          [ngStyle]="item.itemStyle"
          [class.hidden]="!listData[0]"
          [item]="item">
          <span *ngIf="item.field && (item.field.staticValue || item.field.textId || item.field.originalVariableGuidId)"
            class="label"
            [ngStyle]="item.labelStyle">{{
              item.field.staticValue ? item.field.staticValue :
              item.field.textId ? layoutTexts.get(item.field.textId)?.text :
              listData[0] ? listData[0].parseRV(item.field.subVariableMemberGuidId || item.field.originalVariableGuidId) :
              ''
            }}
          </span>
        </gridster-item>
      </gridster>

      <div *ngFor="let repeat of getNgForArray(listData.length / listDefinition.rows); let i = index;"
        style="position: relative;"
        [style.margin]="'0 0 0 0'">
        <gridster class="gridster gridSize"
          [ngStyle]="listDefinition.style"
          [style.flex]="!listDefinition.style?.width ? 0 : undefined"
          [style.maxHeight]="listDefinition.style?.width ? listDefinition.style?.height || listDefinition.style?.minHeight : undefined"
          [style.minHeight]="rowMinHeight"
          [style.maxWidth]="listDefinition.style?.width ? 'calc(' + listDefinition.style.width + ' - 1px)' : ''"
          [options]="gridsterOptions1">
          <gridster-item *ngFor="let item of listDefinition?.items || []; let j = index;"
            [id]="'objectId_' + listData[(i * listDefinition.rows) + (j + headerAddToIndex)]?.objectId"
            class="gridster-item pointer"
            [ngStyle]="item.itemStyle"
            [class.hidden]="!listData[(i * listDefinition.rows) + (j + headerAddToIndex)]"
            [item]="item">
            <gridster *ngIf="i | getDesignStyle:j:thisComponent:pipePureValueBusting"
              class="gridster background"
              [ngStyle]="(i | getDesignStyle:j:thisComponent:pipePureValueBusting).style?.style"
              [options]="gridsterOptions2">
              <gridster-item *ngFor="let item of (i | getDesignStyle:j:thisComponent:pipePureValueBusting).style?.items || []"
                class="gridster-item"
                [ngStyle]="item.itemStyle"
                [item]="item">

                <div class="gridster-item-content">
                  <img *ngIf="(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.isResource"
                    class="image"
                    [ngStyle]="item.labelStyle"
                    [src]="resourceMap[(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.resourceGuidId] ? resourceMap[(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.resourceGuidId] : 'assets/img/loading.gif'"
                    alt="picture" />

                  <span *ngIf="!(item | getItemData:i:j:thisComponent:pipePureValueBusting)?.isResource"
                    class="label"
                    [ngStyle]="item.labelStyle">{{ item | getItemFieldValue:i:j:thisComponent:pipePureValueBusting }}
                  </span>
                </div>
              </gridster-item>
            </gridster>

          </gridster-item>
        </gridster>

        <div>
          <div class="row-counter progress flex-col"
            [style.background-color]="progressColors[(((articleScanCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 0) * 9) / (articleTotalCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 1) | lcNumber: '1.0-0')]"
            [style.width]="((articleScanCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 0) * 100) / (articleTotalCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 1) + '%'">
            <div class="flex-1">
              {{ articleScanCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 0 }}
            </div>

            <div>
              {{ (((articleScanCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 0) * 100) / (articleTotalCountMap?.[listData[(i * listDefinition.rows)]?.dataGuidId] || 1)) | lcNumber: '1.0-0' }}%
            </div>
          </div>
        </div>
      </div>

      <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>
  </div>

</ion-content>

