import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { GridsterConfig } from 'angular-gridster2';
import { mergeMap } from 'rxjs/operators';
import { Resource } from 'src/app/shared/models/resource.model';
import { DesignStyleJson, DesignStyleJsonItem } from 'src/app/shared/models/studio';
import { BasePlugin } from 'src/app/shared/services';
import { LayoutResourceService } from 'src/app/shared/services/protocol/layout-resource.service';
import { CaseUtils, LogUtils } from 'src/app/shared/utils';
import { BlobUtils } from 'src/app/shared/utils/blob.utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { DictNumber, DictString, Notification, Scan } from '../../../models';
import { BARCODE_TYPES } from '../../barcode-scanner/barcode-scanner-livestream/barcode-types';
import { ControlBaseComponent } from '../base/control-base.component';
import { addDays, format } from 'date-fns';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutData } from 'src/app/shared/models/memorypack/RuntimeLayoutData';
import { RuntimeLayoutDesignStyle } from 'src/app/shared/models/memorypack/RuntimeLayoutDesignStyle';
import { RuntimeLayoutObjectPointer } from 'src/app/shared/models/memorypack/RuntimeLayoutObjectPointer';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { Subscription } from 'rxjs';

enum List1DeviceControlUISummaryLocation {
  TopCenter = 0,
  TopRight = 1,
  BottomCenter = 2,
  BottomRight = 3,
}

@Component({
  selector: 'lc-control-list1',
  templateUrl: 'control-list1.component.html',
  styleUrls: ['./control-list1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlList1Component extends ControlBaseComponent implements OnInit {

  readonly barcodeTypes = BARCODE_TYPES;
  readonly defaultScreenCols = 1; // only really used for old designJsons who didn't have .screenCols
  readonly defaultScreenRows = 5; // only really used for old designJsons who didn't have .screenRows

  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;

  activeView: string;
  private clickedData: RuntimeLayoutData;
  designStyleMappings: any[];
  headerDefinition: RuntimeLayoutDesignStyle;
  headerAddToIndex: number;

  private filtersDefinition: any;
  private activeFilter: any;

  private sortsDefinition: any;
  private activeSort: any;
  gridsterOptions1: GridsterConfig;
  gridsterOptions2: GridsterConfig;
  layoutResourceSubscriptionMap: { [key: string]: Subscription } = {};
  listDefinition: any;
  listData: RuntimeLayoutData[];
  listReady: boolean;
  mapData: RuntimeLayoutData[];
  private numOfVisibleItems: number;


  renderedViews: string[] = [];
  resourceMap: DictString<SafeUrl> = {};
  rowDefinition: RuntimeLayoutDesignStyle[];
  rowMinHeight: string;;

  private setDatasObjectPointers: RuntimeLayoutObjectPointer[];
  thisComponent: ControlList1Component;
  uiGroupingMap: DictNumber<{ designStyleGuidId: string, designStyleOriginalGuidId: string, sortValue: string, value: string }>;
  uiSummaryLocation: string;
  uiSummaryValue: number;
  useItem: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    injector: Injector,
    private el: ElementRef,
    private layoutResourceService: LayoutResourceService,
  ) {
    super(injector);

    this.gridsterOptions1 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 0,
      mobileBreakpoint: 0,
    };
    this.gridsterOptions2 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 2,
      mobileBreakpoint: 0,
    };

    this.thisComponent = this;
  }

  ngOnInit() {
    this.clickedData = undefined;
    this.headerDefinition = undefined;
    this.listData = [];
    this.mapData = [];

    if (!this.layoutControl || !this.layoutScreen) {
      this.showMissingFieldNotification('LayoutScreen/LayoutControl');
      return;
    }

    this.viewChanged(RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapView') && RuntimeLayoutUtils.parseRV(this.layoutControl, 'MapDefaultView') ? 'map' : 'list');

    // Get filter definition and default filter
    this.filtersDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'FilterListJson', null));
    this.activeFilter = this.getDefaultFilter();

    // Get sort definition and default sort
    this.sortsDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'SortListJson', null));
    this.activeSort = this.getDefaultSort();

    this.useItem = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UseItem', true); // defaults to true for backwards compatibility

    // Get list definition (for the GRID CSS)
    this.listDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'ListJson', null));
    if (!this.listDefinition) {
      this.showMissingFieldNotification('ListJson');
      return;
    }
    // regression from before gridster on the client
    let innerRowHeights = {};
    for (const item of this.listDefinition.items || []) {
      if (!item.col || !item.row) continue;
      item.x = item.col - 1;
      item.y = item.row - 1;
      item.cols = 1;
      item.rows = 1;
      delete item.col;
      delete item.row;

      const designStyle = this.getItemDesignStyle(item);
      if (!designStyle) continue;

      for (const i of DesignStyleJson.parseDesignStyleJson(designStyle.styleJsonBinary)?.items || []) {
        innerRowHeights[i.y] = Math.max(innerRowHeights[i.y] || 0, parseInt(i.labelStyle.lineHeight));
      }
    }
    const minOuterRowHeight = Object.keys(innerRowHeights).reduce((previousValue: any, currentValue: any) => {
      previousValue += innerRowHeights[currentValue] || 0;
      return previousValue;
    }, 0);
    if (this.listDefinition.style?.minHeight) {
      // FX3 printer old chrome webview doesn't support CSS max()...so we have to try to calculate it in javascript...
      // `max(${this.listDefinition.style.minHeight}, ${minOuterRowHeight}rem)`;
      this.rowMinHeight = this.getMinRowHeight(this.listDefinition.style.minHeight, minOuterRowHeight);
      delete this.listDefinition.style?.minHeight;
    }

    // Get the designStyleMapping
    const designStyleMappingEnabled = RuntimeLayoutUtils.parseRV(this.layoutControl, 'DesignStyleMapping', false);
    if (designStyleMappingEnabled) {
      this.designStyleMappings = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'DesignStyleMappingFilterStringJson', '[]'));
    }

    this.headerDefinition = this.getItemDesignStyle(this.listDefinition.header);
    this.headerAddToIndex = !!this.headerDefinition ? 1 : 0;

    this.rowDefinition = (this.listDefinition.items || [])
    .map(x => this.getItemDesignStyle(x))
    .filter(x => x);

    // Get list data
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');

    this.setDatasObjectPointers = Array.from(this.layoutScreen.sets.get(setId)?.datas?.keys() || [])
    .map((dataId: bigint) => {
      return this.layoutScreen.sets.get(setId).datas.get(dataId);
    });

    this.groupData();

    this.sortData();

    this.numOfVisibleItems = this.populateListDataFromTo(
      0,
      ((this.listDefinition.screenRows || this.defaultScreenRows) * (this.listDefinition.screenCols || this.defaultScreenCols)) - 1,
    );
    this.populateMapData();
    this.doSummaryCalculationIfEnabled();
    this.cdr.markForCheck();

    setTimeout(() => {
      this.listReady = true;
      this.cdr.markForCheck();
    }, 10);
  }

  viewChanged(view: string) {
    if (this.renderedViews.indexOf(view) < 0) {
      this.renderedViews.push(view);
    }
    this.activeView = view;

    this.cdr.markForCheck();
  }

  private showMissingFieldNotification(missingFieldName: string): void {
    this.notificationService.showNotification(new Notification({
      blocking: true,
      text: `${this.translateService.instant('Missing')} ${missingFieldName}!`,
      type: RuntimeLayoutNotifyType.Unknown
    }));
    this.cdr.markForCheck();
  }

  private groupData(): void {
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGrouping')) return;
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingFieldSubMemberGuidId')) {
      this.showMissingFieldNotification('UIGroupingFieldSubMemberGuidId');
      return;
    }
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingStylesGuidIds')) {
      this.showMissingFieldNotification('UIGroupingStylesGuidIds');
      return;
    }

    this.uiGroupingMap = {};
    const uiGroupingDesignStyleGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingStylesGuidIds', '').split(',')[0];
    const uiGroupingDesignStyleOriginalGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingStylesOriginalGuidIds', '').split(',')[0];
    const uiGroupingFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingFieldSubMemberGuidId');
    const uiGroupingSortFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingSortFieldSubMemberGuidId');
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let data = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: this.setDatasObjectPointers[i].objectId });

      const uiGroupingValue = RuntimeLayoutUtils.parseRV(data, uiGroupingFieldSubMemberGuidId, '-');
      const uiGroupingSortValue = RuntimeLayoutUtils.parseRV(data, uiGroupingSortFieldSubMemberGuidId);
      if (Object.values(this.uiGroupingMap).some(x => x.value == uiGroupingValue)) {
        this.uiGroupingMap[data.objectId.toString()] = {
          designStyleGuidId: uiGroupingDesignStyleGuidId,
          designStyleOriginalGuidId: uiGroupingDesignStyleOriginalGuidId,
          sortValue: uiGroupingSortValue != null ? uiGroupingSortValue : uiGroupingValue,
          value: uiGroupingValue,
        };
        continue;
      }

      this.uiGroupingMap[data.objectId.toString()] = {
        designStyleGuidId: uiGroupingDesignStyleGuidId,
        designStyleOriginalGuidId: uiGroupingDesignStyleOriginalGuidId,
        sortValue: uiGroupingSortValue != null ? uiGroupingSortValue : uiGroupingValue,
        value: uiGroupingValue,
      };
      const groupDataObjectPointer = Object.assign(new RuntimeLayoutObjectPointer(), this.setDatasObjectPointers[i]);
      (groupDataObjectPointer as any).$uiGrouping = uiGroupingValue;
      this.setDatasObjectPointers.splice(i, null, groupDataObjectPointer);
      i++;
    }
  }

  private sortData() {
    this.setDatasObjectPointers = (this.setDatasObjectPointers || []).sort((a, b) => {
      let sortByGroupResult;
      let sortByValueResult;

      const aGroup = this.uiGroupingMap?.[a.objectId.toString()] as any;
      const bGroup = this.uiGroupingMap?.[b.objectId.toString()] as any;
      if (typeof aGroup?.sortValue === 'string') {
        sortByGroupResult = (aGroup?.sortValue || '').localeCompare(bGroup?.sortValue || '');
      } else {
        sortByGroupResult = (aGroup?.sortValue - bGroup?.sortValue);
      }

      if (!this.activeSort?.SortOrder) return Number(sortByGroupResult); // -1 Desc, 0 None, 1 Asc

      const aValue = RuntimeLayoutUtils.parseRV(this.layoutScreen.datas.get(a.objectId), this.activeSort.SolutionTypeSubVariableMemberGuidId);
      const bValue = RuntimeLayoutUtils.parseRV(this.layoutScreen.datas.get(b.objectId), this.activeSort.SolutionTypeSubVariableMemberGuidId);
      if (typeof aValue === 'string') {
        sortByValueResult = (aValue || '').localeCompare(bValue || '') * (this.activeSort.SortOrder);
      } else {
        sortByValueResult = Number(aValue) - Number(bValue) * (this.activeSort.SortOrder);
      }

      return sortByGroupResult || sortByValueResult;
    });
  }

  private getDefaultFilter(): any {
    if (!this.filtersDefinition) return null;

    let item = this.filtersDefinition.find((i: any) => {
      return i.Default;
    });
    console.log(item);
    return item;
  }

  private getDefaultSort(): any {
    if (!this.sortsDefinition) return null;

    let item = this.sortsDefinition.find((i: any) => {
      return i.Default;
    });
    console.log(item);
    return item;
  }

  private populateListDataFromTo(startIndex: number, endIndex: number): number {
    for (let i = startIndex; i <= endIndex; i++) {
      if (i >= this.setDatasObjectPointers.length) break;

      let data = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: this.setDatasObjectPointers[i].objectId });

      if ((this.setDatasObjectPointers[i] as any).$uiGrouping) {
        const groupData: any = Object.assign(new RuntimeLayoutData(), data);
        groupData.$uiGrouping = (this.setDatasObjectPointers[i] as any).$uiGrouping;

        this.listData.push(groupData);
        endIndex++; // we don't count the group rows for the total on screen rows
      } else if (this.doesDataPassActiveFilter(data) && this.listData.indexOf(data) < 0) {
        this.listData.push(data);
      } else {
        endIndex++; // if one row doesn't pass the filter, we allow to check for 1 more
      }
    }

    for (let i = 0; i < this.listData.length; i++) {
      const data: any = this.listData[i];
      if (!data?.$uiGrouping) continue;

      const nextData: any = this.listData[i+1];
      const nextDataUiGrouping = this.uiGroupingMap[nextData?.objectId.toString()];
      if (data.$uiGrouping === nextDataUiGrouping?.value) continue;

      this.listData.splice(i, 1);
      i--;
    }

    return this.listData.length;
  }

  private doesDataPassActiveFilter(data: RuntimeLayoutData) {
    if (!this.activeFilter) return true;

    for (const include of this.activeFilter.Included || []) {
      const dataValue = RuntimeLayoutUtils.parseRV(data, include.SolutionTypeSubVariableMemberGuidId);
      let filterValue = RuntimeLayoutUtils.parseLayoutValue(include.Value);
      if (typeof dataValue === 'boolean') {
        filterValue = ['1', 'True', 'true'].indexOf(filterValue) >= 0 ? true
        : ['0', 'False', 'false'].indexOf(filterValue) >= 0 ? false
        : filterValue;
      }

      if (dataValue == filterValue) return true;
    }

    for (const exclude of this.activeFilter.Excluded || []) {
      const dataValue = RuntimeLayoutUtils.parseRV(data, exclude.SolutionTypeSubVariableMemberGuidId);
      let filterValue = RuntimeLayoutUtils.parseLayoutValue(exclude.Value);
      if (typeof dataValue === 'boolean') {
        filterValue = ['1', 'True', 'true'].indexOf(filterValue) >= 0 ? true
        : ['0', 'False', 'false'].indexOf(filterValue) >= 0 ? false
        : filterValue;
      }

      if (dataValue == filterValue) return false;
    }

    return this.activeFilter.Included?.length ? false : true;
  }

  private populateMapData(): void {
    for (let i = 0; i < this.setDatasObjectPointers.length; i++) {
      const data = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!this.doesDataPassActiveFilter(data)) continue;

      this.mapData.push(data);
    }
  }

  private doSummaryCalculationIfEnabled(): void {
    this.uiSummaryValue = undefined;
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummary')) return;
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummaryFieldSubMemberGuidId')) {
      this.showMissingFieldNotification('UISummaryFieldSubMemberGuidId');
      return;
    }

    const uiSummaryFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummaryFieldSubMemberGuidId');
    const uiSummaryLocationEnum = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummaryLocation', List1DeviceControlUISummaryLocation.TopCenter);
    this.uiSummaryLocation = List1DeviceControlUISummaryLocation[uiSummaryLocationEnum].replace(/([A-Z])/g, ($1) => { return '-' + $1.toLowerCase(); }).substring(1);

    this.uiSummaryValue = 0.0;
    for (let setData of this.setDatasObjectPointers || []) {
      if ((setData as any).$uiGrouping) continue;

      let data = this.layoutScreen.datas.get(setData.objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: setData.objectId });
      if (this.doesDataPassActiveFilter(data)) {
        this.uiSummaryValue += RuntimeLayoutUtils.parseRV(data, uiSummaryFieldSubMemberGuidId, 0.0);
      }
    }
  }

  private getItemDesignStyle(item: any): RuntimeLayoutDesignStyle {
    if (!item) return undefined;

    const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
      return GuidUtils.isEqual((ld.originalDesignGuidId), (item.design?.guidId || item.designGuidId))
      || GuidUtils.isEqual((ld.designGuidId), (item.design?.guidId || item.designGuidId));
    });
    if (!design) {
      this.showMissingFieldNotification('Design: ' + (item.design?.label || '') + ' (' + (item.design?.guidId || item.designGuidId || '') + ')');
      return undefined;
    }

    const designStyle = design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
      return GuidUtils.isEqual((lds.originalDesignStyleGuidId), (item.designStyle?.guidId || item.designStyleGuidId))
      || GuidUtils.isEqual((lds.designStyleGuidId), (item.designStyle?.guidId || item.designStyleGuidId));
    });
    if (!designStyle) {
      this.showMissingFieldNotification('DesignStyle: ' + (item.designStyle?.label || '') + ' (' + (item.designStyle?.guidId || item.designStyleGuidId || '') + ')');
      return undefined;
    } else if (!DesignStyleJson.parseDesignStyleJson(designStyle.styleJsonBinary)) {
      this.notificationService.showNotification(new Notification({
        blocking: true,
        title: this.translateService.instant('Empty DesignStyle!'),
        text: designStyle.designStyleGuidId,
        type: RuntimeLayoutNotifyType.Unknown
      }));
    }
    return designStyle;
  }

  getDesignStyleWithMapping(i: number, j: number): RuntimeLayoutDesignStyle | null {
    const itemData = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];

    if ((itemData as any)?.$uiGrouping) { // this means it's a UIGroup
      const designStyleGuidId = this.uiGroupingMap?.[itemData.objectId.toString()]?.designStyleGuidId;
      const designStyleOriginalGuidId = this.uiGroupingMap?.[itemData.objectId.toString()]?.originalDesignStyleGuidId;
      const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
        return ld.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
          return GuidUtils.isEqual((lds.originalDesignStyleGuidId), designStyleOriginalGuidId)
          || GuidUtils.isEqual((lds.designStyleGuidId), designStyleGuidId);
        });
      });
      if (design) {
        return this.getItemDesignStyle({ designGuidId: design.designGuidId, designStyleGuidId: designStyleGuidId });
      }
    }

    if (!this.designStyleMappings?.length) return this.rowDefinition[j % this.listDefinition.rows];

    for (const designStyleMapping of this.designStyleMappings || []) {
      let groupResult = null;
      for (const filterGroup of designStyleMapping.filterStringFilter?.filterGroups || []) {
        let stepResult = this.evalFilterGroupResult(filterGroup, itemData);
        groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
      }
      if (groupResult) return this.getItemDesignStyle(designStyleMapping);
    }
    return this.rowDefinition[j % this.listDefinition.rows];
  }

  getItemData(item: DesignStyleJsonItem, i: number, j: number): RuntimeLayoutData {
    if (!this.listData?.length) return null;

    const listDataAtIndex = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];
    if (!item.field?.originalVariableGuidId) return listDataAtIndex;

    let data = null;
    if (item.field?.subVariableMemberGuidId) {
      const valueRaw = listDataAtIndex.values?.get(item.field.subVariableMemberGuidId);
      if (valueRaw?.extendedValueType === 'Resource') {
        data = Array.from(this.layoutScreen.datas.values()).find(x => x.dataGuidId === JSON.parse(valueRaw.valueJson));
      }
    }
    if (!data && this.layoutScreen.variables) {
      const variable = Array.from(this.layoutScreen.variables.values()).find(x => x.originalVariableGuidId === item.field.originalVariableGuidId);
      if (variable) {
        data = this.layoutScreen.datas.get(BigInt(variable.value));
      }
    }

    if (data?.isResource && !this.layoutResourceSubscriptionMap[data.resourceGuidId + '_' + data.resourceTick]) {
      // make sure we only request it once per resourceGuidId_resourceTick
      this.layoutResourceSubscriptionMap[data.resourceGuidId + '_' + data.resourceTick] = this.layoutResourceService.get(data.resourceGuidId, data.resourceTick)
      .pipe(
        mergeMap((resource: Resource) => {
          const blob: Blob = new Blob([resource.content], { type: resource.contentType });
          return BlobUtils.blobToDataURL(blob);
        })
      )
      .subscribe((dataUrl: string) => {
        this.resourceMap[data.resourceGuidId] = this.domSanitizer.bypassSecurityTrustUrl(dataUrl);
        this.cdr.markForCheck();
      });
    }

    return data;
  }

  getItemFieldValue(item: DesignStyleJsonItem, i: number, j: number): string {
    if (!item.field) return item.valueIfNull || '';

    const listDataAtIndex = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];
    if (!listDataAtIndex?.values?.size) return `[CORRUPT DATA ID: ${listDataAtIndex.objectId}]`;

    let result = item.field.staticValue != null ? item.field.staticValue
    : item.field.textId ? this.layoutTexts.get(item.field.textId)?.text
    : RuntimeLayoutUtils.parseRV(listDataAtIndex, item.field.subVariableMemberGuidId || item.field.originalVariableGuidId);

    result = result != null ? result : (item.valueIfNull || '');
    result = result.toString().replace(/\[\[([^\]]+)\]\]/g, '<i class="$1"></i>'); // replace font awesome icons in the format [[fad fa-check]]

    if (!item.cellSyntax) return result;

    // lets try to parse and execute the cellSyntax...
    let cellSyntaxResult = item.cellSyntax.slice(0);

    cellSyntaxResult = cellSyntaxResult
    .replace(/{{value}}/g, '[[value]]') // regression since the placeholder brackets changed from the original {{value}} for consistency and not conflict with @if() { }...
    .replace(/\[\[value\]\]/g, result) // replace actual value
    .replace(/"/g, '') // remove quotes
    .replace(/'/g, '') // remove quotes
    .replace(/\[\[([^\]]+)\]\]/g, '<i class="$1"></i>'); // replace font awesome icons in the format [[fad fa-check]]

    let ifMatches = null;
    let found = false;
    do {
      ifMatches = /@(if|else if)\s\(([^)]+)\)\s{([^}]+)}/gi.exec(cellSyntaxResult);
      if (!ifMatches) break;

      if (!found && ifMatches?.[2].trim() == result) {
        cellSyntaxResult = cellSyntaxResult.replace(ifMatches[0], ifMatches[3]);

        found = true;
      }
      else cellSyntaxResult = cellSyntaxResult.replace(ifMatches[0], '');
    } while (ifMatches?.length);

    const elseMatch = /@else\s{([^}]+)}/gi.exec(cellSyntaxResult);
    if (elseMatch) {
      if (!found && elseMatch.length > 1) cellSyntaxResult = cellSyntaxResult.replace(elseMatch[0], elseMatch[1]);
      else cellSyntaxResult = cellSyntaxResult.replace(elseMatch[0], '');
    }

    return cellSyntaxResult.trim();
  }

  private evalFilterGroupResult(outterfilterGroup: any, itemData: RuntimeLayoutData): boolean {
    if (!outterfilterGroup.filter?.filterGroups?.length) {
      return this.evalFilterResult(outterfilterGroup.filter, itemData);
    }

    let groupResult = null;
    for (const filterGroup of outterfilterGroup.filter?.filterGroups || []) {
      let stepResult = this.evalFilterResult(filterGroup.filter, itemData);
      groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
    }
    return groupResult;
  }

  private evalFilterResult(filter: any, itemData: RuntimeLayoutData) {
    if (!filter || !itemData?.values) return null;

    let stepResult = null;
    const itemDataValue = RuntimeLayoutUtils.parseRV(itemData, GuidUtils.clean(filter.member.guidId));
    let filterValue = filter.value;
    if (filter.member.fieldType === 'date-relative' && filterValue.indexOf('{{today}}') >= 0) {
      const today = new Date();
      const add = (filterValue || '').indexOf('+') >= 0;
      const minus = (filterValue || '').indexOf('-') >= 0;
      const relativeDays = (filterValue || '').match(/{{today}}\s*[+-]\s*(\d+)/)?.[1] || 0;
      if (add || minus) filterValue = format(addDays(today, (minus ? -1 : 1) * relativeDays), 'yyyy-MM-dd');
      else filterValue = format(today, 'yyyy-MM-dd');
    }
    switch (filter.operator) {
      case '=':
        stepResult = filterValue == itemDataValue;
        break;
      case '!=':
        stepResult = filterValue != itemDataValue;
        break;
      case '<':
        stepResult = filterValue < itemDataValue;
        break;
      case '<=':
        stepResult = filterValue <= itemDataValue;
        break;
      case '>':
        stepResult = filterValue > itemDataValue;
        break;
      case '>=':
        stepResult = filterValue >= itemDataValue;
        break;
    }
    return stepResult;
  }

  protected advanceCallback(scan: Scan, plugin?: BasePlugin) {
    const scanItemEnabled = RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanItemEnabled', false);
    const scanItemSearchMemberGuidIds = RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanItemSearchMemberGuidIds', '');
    if (!scanItemEnabled || !scanItemSearchMemberGuidIds) return super.advanceCallback(scan, plugin);

    // try to search the list items to see if we get a match...
    for (let setData of this.setDatasObjectPointers || []) {
      let data = this.layoutScreen.datas.get(setData.objectId);
      for (const searchMemberGuidId of scanItemSearchMemberGuidIds.split(',') || []) {
        if (data.values[searchMemberGuidId]?.parse() != scan.value) continue;

        this.ngZone.run(() => {
          if (plugin) plugin.action({ command: 'stop' }).subscribe();
          LogUtils.log('scanner.advanceCallback() - list item click:', data);
          this.itemClick(data, null, 'Item');
        });
        return;
      }
    }

    return super.advanceCallback(scan, plugin);
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    if (!this.clickedData) return null;

    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    const set = this.layoutScreen.sets.get(setId);

    const context = new Map<string, RuntimeLayoutValue | null>();
    context.set('SourceRuntimeObjectId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(set ? set.runtimeSetObjectId : 0),
      valueTypeId: RuntimeLayoutValueType.Int
    }));
    context.set('ItemRuntimeObjectId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.clickedData.runtimeDataObjectId),
      valueTypeId: RuntimeLayoutValueType.Int
    }));
    context.set('ItemEnvironmentGuidId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.clickedData.environmentGuidId),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    context.set('ItemGuidId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.clickedData.dataGuidId),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    return context;
  }

  itemClick(data: RuntimeLayoutData, designStyle?: RuntimeLayoutDesignStyle, portName?: string) {
    if (!this.useItem) return;
    if (!Object.keys(data?.values || {})) return;
    if ((data as any)?.$uiGrouping) return;
    if (designStyle?.notClickable) return;

    this.vibrationService.vibrate();
    this.clickedData = data;

    const grouping = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Grouping', false);

    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('EventCode', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify('ListItem'),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: portName ? JSON.stringify(portName)
      : grouping ? JSON.stringify('Items')
      : JSON.stringify('Item'),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

  getNgForArray(repetitions: number) {
    if (repetitions > ~~repetitions) {
      repetitions = ~~repetitions + 1; // round up if required...
    }
    return Array(repetitions).fill(1).map((x,i) => i + 1);
  }

  doInfinite(event: any) {
    setTimeout(() => {
      this.numOfVisibleItems = this.populateListDataFromTo(
        this.numOfVisibleItems,
        this.numOfVisibleItems + (this.listDefinition.screenRows || this.defaultScreenRows) - 1
      );

      event.target.complete();
      this.cdr.markForCheck();
    }, 10);
  }

  getMinRowHeight(rowHeightPercentage: number | string, minRem: number | string) {
    return Math.max(
      this.convertRowHeightPercentageToPixels(rowHeightPercentage),
      this.convertRemToPixels(minRem),
    ) + 'px'
  }

  private convertRowHeightPercentageToPixels(rowHeightPercentage: number | string) {
    return (parseFloat(rowHeightPercentage.toString()) / 100) * parseFloat(getComputedStyle(this.el.nativeElement).height);
  }

  private convertRemToPixels(rem: number | string) {
    return parseFloat(rem.toString()) * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

}

