import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { RuntimeLayoutData } from 'src/app/shared/models/memorypack/RuntimeLayoutData';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { PlatformResource } from 'src/app/shared/models/platform-resource.model';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { CaseUtils, LogUtils } from 'src/app/shared/utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { ControlBaseComponent } from '../base/control-base.component';
import { HybridDataSyncronizationState, HybridEventQueueSecurityLevel, HybridState, LayoutState } from 'src/app/shared/models/layout-state.model';


@Component({
  selector: 'lc-control-menu1',
  templateUrl: 'control-menu1.component.html',
  styleUrls: ['./control-menu1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlMenu1Component extends ControlBaseComponent implements OnInit {

  readonly LABEL_GUID: string = '1af7e2dc159a4b01b346db0734a1f502';

  menuDefinition: any;
  menuData: RuntimeLayoutData[];
  mobileEngineLayoutState: LayoutState;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.subscriptions.push(
      this.appService.listenToMobileEngineLayoutState()
      .subscribe((layoutState: LayoutState) => {
        this.mobileEngineLayoutState = layoutState;
        this.refreshButtonsWithMobileEngineLayoutState();
        this.cdr.markForCheck();
      }),
    );
  }

  ngOnInit() {
    this.menuData = [];

    if (!this.layoutControl || !this.layoutScreen) return;

    // Get menu definition (for the GRID CSS)
    this.menuDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'MenuStyleJson', '{}'));

    this.populateMenuData();
  }

  private populateMenuData(): void {
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');

    if (!this.layoutScreen.sets || !this.layoutScreen.sets.has(setId)) {
      LogUtils.warn(`populateMenuData() - layoutScreen.sets not found for ${setId}...`);
      return;
    }

    for (const dataObjectId of this.layoutScreen.sets.get(setId).datas.keys()) {
      this.menuData.push(this.layoutScreen.datas.get(dataObjectId));
    }

    // sort buttons according to menuDefinition (if any)
    const menuDefinitionItemsArray = (this.menuDefinition.items || []).map((item: any) => {
      return item.guidId.replace(/-/g, '');
    });
    if (menuDefinitionItemsArray?.length) {
      this.menuData = this.menuData.slice(0).sort((a, b) => {
        return menuDefinitionItemsArray.indexOf(a.dataGuidId) - menuDefinitionItemsArray.indexOf(b.dataGuidId);
      });
    }

    // parse the PlatformResource icons if any
    const menuJson = CaseUtils.toCamel(JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'MenuJson', '{}')));
    this.menuData = (this.menuData || []).map((b: any) => {
      const existingButton = (menuJson.buttons || []).find(x => GuidUtils.isEqual(x.buttonGuidId, b.dataGuidId));
      if (existingButton?.resourceId) {
        b.icon = PlatformResource.resourceMap[existingButton?.resourceId];
      }

      return b;
    });

    this.mobileEngineLayoutState = this.appService.getMobileEngineLayoutState();
    this.refreshButtonsWithMobileEngineLayoutState();
    this.cdr.markForCheck();
  }

  private refreshButtonsWithMobileEngineLayoutState() {
    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'online') return;

    // for each button, check if it should be enabled or disabled according to hybrid state requirements and such
    this.menuData = (this.menuData || []).map((b: any) => {
      // b.hybridDataSynchronizationRequirement = 0 | 1 (None | Ready)
      b.disabled = !this.mobileEngineLayoutState
      || (b.hybridDataSynchronizationRequirement === 1 && this.mobileEngineLayoutState.hybridDataSyncronizationState !== HybridDataSyncronizationState.Ready)
      || (b.supportHybridState !== 2 && !this.mobileEngineLayoutState.online && this.mobileEngineLayoutState.hybridState === HybridState.Offline)
      || (b.hybridEventQueueSecurityLevel > 0 && !GuidUtils.isNullOrEmpty(b.hybridEventQueueGuidId) && b.hybridEventQueueMaxEvents != null && b.hybridEventQueueMaxEvents < ((b.hybridEventQueueSecurityLevel === HybridEventQueueSecurityLevel.FlowSecured || b.hybridEventQueueSecurityLevel === HybridEventQueueSecurityLevel.FlowFallbackSecured) ? (this.mobileEngineLayoutState.flowQueueCount[b.hybridEventQueueGuidId] || 0) : (this.mobileEngineLayoutState.queueCount || 0)));

      return b;
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    return null;
  }

  buttonClick(platformObjectGuidId: string) {
    this.vibrationService.vibrate();

    const platformObjectType = RuntimeLayoutEventPlatformObjectType.Menu;
    this.triggerEvent.emit({
      platformObjectType: platformObjectType,
      platformObjectGuidId: platformObjectGuidId,
    });
  }

}

