import { BluetoothDevice } from './bluetooth-device.model';
import { RuntimeLayoutNativeKeyboard } from './runtime-layout/runtime-layout-native-keyboard.enum';


export class Settings {

  btDevices: BluetoothDevice[];
  fontSize: string;
  fullScreen: boolean;
  keyboard: RuntimeLayoutNativeKeyboard;
  keyboardChangedByUser: boolean;
  operationalMode: 'online' | 'hybrid';
  runDeviceDebug: boolean;
  runDeviceDebugTestDeveloper: boolean;
  screenOrientation: string;
  theme: string;
  vibration: boolean;

  constructor(settings?: any) {
    Object.assign(this, settings);

    this.btDevices = (this.btDevices || []).map((device: BluetoothDevice) => {
      return new BluetoothDevice(device);
    });
    this.fontSize = this.fontSize || 'medium';
    this.fullScreen = this.fullScreen || false;
    this.keyboard = this.isNumeric(this.keyboard) ? this.keyboard : RuntimeLayoutNativeKeyboard.LogicCenter; // isNumeric() is because when this was first implemented, keyboard was a string...
    this.keyboardChangedByUser = this.keyboardChangedByUser || false;
    this.operationalMode = this.operationalMode || 'online';
    this.runDeviceDebug = this.runDeviceDebug || false;
    this.runDeviceDebugTestDeveloper = this.runDeviceDebugTestDeveloper || false;
    this.screenOrientation = this.screenOrientation || 'portrait-primary';
    this.theme = this.theme || 'light';
    this.vibration = this.vibration != null ? this.vibration : true;

    // Regression, since we changed this with existing devices having the old setting set
    if (this.screenOrientation === 'portrait') this.screenOrientation = 'portrait-primary';
  }

  private isNumeric(str: any) {
    return !isNaN(parseFloat(str)) && isFinite(str);
  }

  toJSON() {
    return {
      btDevices: this.btDevices,
      fontSize: this.fontSize,
      fullScreen: this.fullScreen,
      keyboard: this.keyboard,
      keyboardChangedByUser: this.keyboardChangedByUser,
      operationalMode: this.operationalMode,
      screenOrientation: this.screenOrientation,
      theme: this.theme,
      vibration: this.vibration,
    };
  }

}
