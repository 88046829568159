import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { LogUtils } from 'src/app/shared/utils';
import { DictString } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';

class NativeExternalSetup {
  autoOpen: boolean;
  externalUrl: string;
  androidIntentAction: string;
  /** @deprecated */ androidPackageName?: string;
  androidUrl: string;
  iosUriScheme: string;
}

@Component({
  selector: 'lc-control-external1',
  templateUrl: 'control-external1.component.html',
  styleUrls: ['./control-external1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlExternal1Component extends ControlBaseComponent implements OnInit {

  nativeExternalSetup: NativeExternalSetup;
  theme: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private iab: InAppBrowser,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.nativeExternalSetup = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'NativeExternalSetupJson', '{}'));
    if (this.nativeExternalSetup.androidPackageName && !this.nativeExternalSetup.androidUrl) {
      this.nativeExternalSetup.androidUrl = 'android-app://' + this.nativeExternalSetup.androidPackageName;
      delete this.nativeExternalSetup.androidPackageName;
    }

    if (!this.nativeExternalSetup.autoOpen) return;

    this.openExternal();
    this.cdr.markForCheck();
  }

  openExternal() {
    LogUtils.log('openExternal()', this.nativeExternalSetup);

    const waitUntilDone = RuntimeLayoutUtils.parseRV(this.layoutControl, 'WaitUntilDone') && this.platform.is('cordova');

    // https://developers.google.com/maps/documentation/urls/get-started
    if (this.platform.is('android')) {
      if (this.nativeExternalSetup.androidIntentAction || this.nativeExternalSetup.androidUrl) {
        const requestCode = ~~(Math.random() * 1000);
        if (waitUntilDone) {
          (window as any).plugins.intentShim.startActivityForResult({
            action: (window as any).plugins.intentShim[this.nativeExternalSetup.androidIntentAction],
            requestCode: requestCode,
            url: this.nativeExternalSetup.androidUrl,
          },
            (intent: any) => {
              if (intent.extras.requestCode !== requestCode) return;

              LogUtils.log('openExternalResult: ', intent);
              this.continueWithPort('Completed');
            },
            () => { }, // Failure in sending the intent, not failure of processing the intent.
          );
        } else {
          (window as any).plugins.intentShim.startActivity({
            action: (window as any).plugins.intentShim[this.nativeExternalSetup.androidIntentAction],
            requestCode: requestCode,
            url: this.nativeExternalSetup.androidUrl,
          },
            () => { }, // Success in sending the intent, not success of processing the intent.
            () => { }, // Failure in sending the intent, not failure of processing the intent.
          );
          this.continueWithPort('Completed');
        }

      } else {
        this.iab.create(
          this.nativeExternalSetup.androidUrl,
          '_system',
        );
        if (!waitUntilDone) this.continueWithPort('Completed');
      }
    } else if (this.platform.is('ios') && this.nativeExternalSetup.iosUriScheme) {
      this.iab.create(
        this.nativeExternalSetup.iosUriScheme,
        '_system',
      );
      if (!waitUntilDone) this.continueWithPort('Completed');
    } else if (this.nativeExternalSetup.externalUrl) {
      const browser = this.iab.create(
        this.nativeExternalSetup.externalUrl,
        waitUntilDone ? '_blank' : '_system',
        { toolbarcolor: '#0072bc', toolbartranslucent: 'no' },
      );
      if (!waitUntilDone) {
        this.continueWithPort('Completed');
        return;
      }

      if (!browser?.on) return;

      browser.on('loaderror')?.subscribe((ev: any) => {
        LogUtils.error('openExternal() error ' + ev?.code + ': ' + ev?.message);
        browser.close();
        this.continueWithPort('Failed');
      });
      browser.on('exit')?.subscribe((ev: any) => {
        this.continueWithPort('Completed');
      });
    }
  }

  private continueWithPort(portName: 'Completed' | 'Failed') {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    return context;
  }

}

