import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GridsterComponent, GridsterConfig } from 'angular-gridster2';
import { Observable, Observer, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Resource } from 'src/app/shared/models/resource.model';
import { DesignStyleJson, DesignStyleJsonItem } from 'src/app/shared/models/studio';
import { BasePlugin } from 'src/app/shared/services';
import { LayoutResourceService } from 'src/app/shared/services/protocol/layout-resource.service';
import { LogUtils } from 'src/app/shared/utils';
import { BlobUtils } from 'src/app/shared/utils/blob.utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { DictNumber, DictString, Notification, Scan } from '../../../models';
import { KeyboardType } from '../../../models/keyboard-type.enum';
import { BARCODE_TYPES } from '../../barcode-scanner/barcode-scanner-livestream/barcode-types';
import { KeyboardService } from '../../keyboard';
import { ControlBaseComponent } from '../base/control-base.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RuntimeLayoutDesign } from 'src/app/shared/models/memorypack/RuntimeLayoutDesign';
import { RuntimeLayoutText } from 'src/app/shared/models/memorypack/RuntimeLayoutText';
import { RuntimeLayoutData } from 'src/app/shared/models/memorypack/RuntimeLayoutData';
import { RuntimeLayoutDesignStyle } from 'src/app/shared/models/memorypack/RuntimeLayoutDesignStyle';
import { RuntimeLayoutSetData } from 'src/app/shared/models/memorypack/RuntimeLayoutSetData';
import { RuntimeLayoutSet } from 'src/app/shared/models/memorypack/RuntimeLayoutSet';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/runtime-layout-notify-type.enum';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import structuredClone from '@ungap/structured-clone';


enum List1DeviceControlUISummaryLocation {
  TopCenter = 0,
  TopRight = 1,
  BottomCenter = 2,
  BottomRight = 3,
}

export enum QuantityList1LineBehaviour {
  Add = 0,
  Remove = 1,
}
export enum QuantityList1ScanBehaviour {
  None = 0,
  QuantityChange = 1,
}

@Component({
  selector: 'lc-control-quantity-list1',
  templateUrl: 'control-quantity-list1.component.html',
  styleUrls: ['./control-quantity-list1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlQuantityList1Component extends ControlBaseComponent implements OnInit {

  readonly barcodeTypes = BARCODE_TYPES;
  readonly defaultScreenCols = 1; // only really used for old designJsons who didn't have .screenCols
  readonly defaultScreenRows = 5; // only really used for old designJsons who didn't have .screenRows

  @ViewChildren(GridsterComponent) gridsterComponents: QueryList<GridsterComponent>;

  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: Map<number, RuntimeLayoutText | null> | null;

  activeKeyboardForData: RuntimeLayoutData;
  private clickedData: RuntimeLayoutData;
  designStyleMappings: any[];
  headerDefinition: RuntimeLayoutDesignStyle;
  headerAddToIndex: number;

  private filtersDefinition: any;
  private activeFilter: any;

  private sortsDefinition: any;
  private activeSort: any;
  gridsterOptions1: GridsterConfig;
  gridsterOptions2: GridsterConfig;
  ignoreButtonOverride: boolean;
  layoutResourceSubscriptionMap: { [key: string]: Subscription } = {};
  lineBehaviour: QuantityList1LineBehaviour;
  listDefinition: any;
  listData: RuntimeLayoutData[];
  listReady: boolean;
  private numOfVisibleItems: number;
  private originalBackButton: boolean;
  private originalButtonsType: number;
  private originalForwardButton: boolean;
  private originalSetDatas: DictNumber<RuntimeLayoutSetData>
  pipePureValueBusting: number = 0;
  quantityScanBehaviour: QuantityList1ScanBehaviour;
  quantityScanEnabled: boolean;
  resourceMap: DictString<SafeUrl> = {};
  rowDefinition: RuntimeLayoutDesignStyle[];
  rowMinHeight: string;;

  private setComplexValueName: string;
  private setComplexTriggerValueName: string;
  private setDatasObjectPointers: RuntimeLayoutSetData[];
  thisComponent: ControlQuantityList1Component;
  uiGroupingMap: DictString<{ designStyleGuidId: string, originalDesignStyleGuidId: string, sortValue: string, value: string }>;
  uiSummaryLocation: string;
  uiSummaryValue: number;
  undoStackLayoutSet: RuntimeLayoutSet[];
  private undoStackLayoutDatas: Map<bigint, RuntimeLayoutData>[];
  updateContextArray: any[];
  useItem: boolean;
  useUpdate: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    injector: Injector,
    private el: ElementRef,
    private keyboardService: KeyboardService,
    private layoutResourceService: LayoutResourceService,
  ) {
    super(injector);

    this.gridsterOptions1 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 0,
      mobileBreakpoint: 0,
    };
    this.gridsterOptions2 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 2,
      mobileBreakpoint: 0,
    };

    this.thisComponent = this;
  }

  ngOnInit() {
    this.clickedData = undefined;
    this.headerDefinition = undefined;

    if (!this.layoutControl || !this.layoutScreen) {
      this.showMissingFieldNotification('LayoutScreen/LayoutControl');
      return;
    }

    this.originalBackButton = this.layoutScreen.backButton;
    this.originalForwardButton = this.layoutScreen.forwardButton;
    this.originalButtonsType = RuntimeLayoutUtils.parseRV(this.layoutScreen, 'ButtonsType', 0);

    // Get filter definition and default filter
    this.filtersDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'FilterListJson', null));
    this.activeFilter = this.getDefaultFilter();

    // Get sort definition and default sort
    this.sortsDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'SortListJson', null));
    this.activeSort = this.getDefaultSort();

    // Get QuantityList specific render values
    this.lineBehaviour = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'LineBehaviour', QuantityList1LineBehaviour.Add));
    this.quantityScanEnabled = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'QuantityScanEnabled', false));
    this.quantityScanBehaviour = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'QuantityScanBehaviour', QuantityList1ScanBehaviour.None));
    this.setComplexValueName = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SetComplexValueName', null);
    this.setComplexTriggerValueName = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SetComplexTriggerValueName', null);
    this.useItem = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UseItem', true); // defaults to true for backwards compatibility
    this.useUpdate = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UseUpdate', false);
    if (!this.setComplexValueName) {
      this.showMissingFieldNotification('SetComplexValueName');
      return;
    }
    // Get list definition (for the GRID CSS)
    this.listDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'QuantityListJson', null));
    if (!this.listDefinition) {
      this.showMissingFieldNotification('QuantityListJson');
      return;
    }

    if (this.listDefinition.style?.minHeight) {
      // FX3 printer old chrome webview doesn't support CSS max()...so we have to try to calculate it in javascript...
      // `max(${this.listDefinition.style.minHeight}, ${minOuterRowHeight}rem)`;
      this.rowMinHeight = this.getMinRowHeight(this.listDefinition.style.minHeight, 0);
      delete this.listDefinition.style?.minHeight;
    }

    // Get the designStyleMapping
    const designStyleMappingEnabled = RuntimeLayoutUtils.parseRV(this.layoutControl, 'DesignStyleMapping', false);
    if (designStyleMappingEnabled) {
      this.designStyleMappings = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'DesignStyleMappingFilterStringJson', '[]'));
    }

    this.headerDefinition = this.getItemDesignStyle(this.listDefinition.header);
    this.headerAddToIndex = !!this.headerDefinition ? 1 : 0;

    this.rowDefinition = (this.listDefinition.items || [])
    .map(x => this.getItemDesignStyle(x))
    .filter(x => x);

    this.cdr.markForCheck();

    this.refresh();
  }

  refresh() {
    // Get list data
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    this.setDatasObjectPointers = Array.from(this.layoutScreen.sets.get(setId)?.datas?.keys() || [])
    .map((dataId: bigint) => {
      const setData = this.layoutScreen.sets.get(setId).datas.get(dataId);
      const data = this.layoutScreen.datas.get(setData.objectId);
      if (!setData.setComplexDataValues.has(this.setComplexValueName) && data) {
        setData.setComplexDataValues = Object.assign(
          new Map<string, RuntimeLayoutValue | null>(),
          setData.setComplexDataValues,
        );
        setData.setComplexDataValues.set(this.setComplexValueName, Object.assign(new RuntimeLayoutValue(), {
          valueJson: this.getQuantityChangeCorrespondingDataValue(data),
          valueTypeId: RuntimeLayoutValueType.Double,
        }));
      }
      return setData;
    });

    this.originalSetDatas = JSON.parse(JSON.stringify(this.layoutScreen.sets.get(setId)?.datas));

    this.groupData();

    this.sortData();

    this.listData = [];
    this.numOfVisibleItems = this.populateListDataFromTo(
      0,
      ((this.listDefinition.screenRows || this.defaultScreenRows) * (this.listDefinition.screenCols || this.defaultScreenCols)) - 1,
    );
    this.doSummaryCalculationIfEnabled();
    this.cdr.markForCheck();

    setTimeout(() => {
      this.listReady = true;
      this.cdr.markForCheck();
    }, 10);
  }

  private showMissingFieldNotification(missingFieldName: string): void {
    this.notificationService.showNotification(new Notification({
      blocking: true,
      text: `${this.translateService.instant('Missing')} ${missingFieldName}!`,
      type: RuntimeLayoutNotifyType.Unknown
    }));
    this.cdr.markForCheck();
  }

  private groupData(): void {
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGrouping')) return;
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingFieldSubMemberGuidId')) {
      this.showMissingFieldNotification('UIGroupingFieldSubMemberGuidId');
      return;
    }
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingStylesGuidIds')) {
      this.showMissingFieldNotification('UIGroupingStylesGuidIds');
      return;
    }

    this.uiGroupingMap = {};
    const uiGroupingDesignStyleGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingStylesGuidIds', '').split(',')[0];
    const uiGroupingoriginalDesignStyleGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingStylesOriginalGuidIds', '').split(',')[0];
    const uiGroupingFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingFieldSubMemberGuidId');
    const uiGroupingSortFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UIGroupingSortFieldSubMemberGuidId');
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let data = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: this.setDatasObjectPointers[i].objectId });

      const uiGroupingValue = RuntimeLayoutUtils.parseRV(data, uiGroupingFieldSubMemberGuidId, '-');
      const uiGroupingSortValue = RuntimeLayoutUtils.parseRV(data, uiGroupingSortFieldSubMemberGuidId);
      if (Object.values(this.uiGroupingMap).some(x => x.value == uiGroupingValue)) {
        this.uiGroupingMap[data.objectId.toString()] = {
          designStyleGuidId: uiGroupingDesignStyleGuidId,
          originalDesignStyleGuidId: uiGroupingoriginalDesignStyleGuidId,
          sortValue: uiGroupingSortValue != null ? uiGroupingSortValue : uiGroupingValue,
          value: uiGroupingValue,
        };
        continue;
      }

      this.uiGroupingMap[data.objectId.toString()] = {
        designStyleGuidId: uiGroupingDesignStyleGuidId,
        originalDesignStyleGuidId: uiGroupingoriginalDesignStyleGuidId,
        sortValue: uiGroupingSortValue != null ? uiGroupingSortValue : uiGroupingValue,
        value: uiGroupingValue,
      };
      const groupDataObjectPointer = Object.assign(new RuntimeLayoutSetData(), this.setDatasObjectPointers[i]);
      (groupDataObjectPointer as any).$uiGrouping = uiGroupingValue;
      this.setDatasObjectPointers.splice(i, 0, groupDataObjectPointer);
      i++;
    }
  }

  private sortData() {
    this.setDatasObjectPointers = (this.setDatasObjectPointers || []).sort((a, b) => {
      let sortByGroupResult;
      let sortByValueResult;

      const aGroup = this.uiGroupingMap?.[a.objectId.toString()] as any;
      const bGroup = this.uiGroupingMap?.[b.objectId.toString()] as any;
      if (typeof aGroup?.sortValue === 'string') {
        sortByGroupResult = (aGroup?.sortValue || '').localeCompare(bGroup?.sortValue || '');
      } else {
        sortByGroupResult = (aGroup?.sortValue - bGroup?.sortValue);
      }

      if (!this.activeSort?.SortOrder) return Number(sortByGroupResult); // -1 Desc, 0 None, 1 Asc

      const aValue = RuntimeLayoutUtils.parseRV(this.layoutScreen.datas.get(a.objectId), this.activeSort.SolutionTypeSubVariableMemberGuidId);
      const bValue = RuntimeLayoutUtils.parseRV(this.layoutScreen.datas.get(b.objectId), this.activeSort.SolutionTypeSubVariableMemberGuidId);
      if (typeof aValue === 'string') {
        sortByValueResult = (aValue || '').localeCompare(bValue || '') * (this.activeSort.SortOrder);
      } else {
        sortByValueResult = Number(aValue) - Number(bValue) * (this.activeSort.SortOrder);
      }

      return sortByGroupResult || sortByValueResult;
    });
  }

  private getDefaultFilter(): any {
    if (!this.filtersDefinition) return null;

    let item = this.filtersDefinition.find((i: any) => {
      return i.Default;
    });
    return item;
  }

  private getDefaultSort(): any {
    if (!this.sortsDefinition) return null;

    let item = this.sortsDefinition.find((i: any) => {
      return i.Default;
    });
    console.log(item);
    return item;
  }

  private populateListDataFromTo(startIndex: number, endIndex: number): number {
    for (let i = startIndex; i <= endIndex; i++) {
      if (i >= this.setDatasObjectPointers.length) break;

      let data = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: this.setDatasObjectPointers[i].objectId });

      if ((this.setDatasObjectPointers[i] as any).$uiGrouping) {
        const groupData: any = Object.assign(new RuntimeLayoutData(), data);
        groupData.$uiGrouping = (this.setDatasObjectPointers[i] as any).$uiGrouping;

        this.listData.push(groupData);
        endIndex++; // we don't count the group rows for the total on screen rows
      } else if (this.doesDataPassActiveFilter(data) && this.listData.indexOf(data) < 0) {
        this.listData.push(data);
      } else {
        endIndex++; // if one row doesn't pass the filter, we allow to check for 1 more
      }
    }

    for (let i = 0; i < this.listData.length; i++) {
      const data: any = this.listData[i];
      if (!data?.$uiGrouping) continue;

      const nextData: any = this.listData[i+1];
      const nextDataUiGrouping = this.uiGroupingMap[nextData?.objectId];
      if (data.$uiGrouping === nextDataUiGrouping?.value) continue;

      this.listData.splice(i, 1);
      i--;
    }

    return this.listData.length;
  }

  private doesDataPassActiveFilter(data: RuntimeLayoutData) {
    if (!this.activeFilter) return true;

    for (const include of this.activeFilter.Included || []) {
      const dataValue = RuntimeLayoutUtils.parseRV(data, include.SolutionTypeSubVariableMemberGuidId);
      let filterValue = RuntimeLayoutUtils.parseLayoutValue(include.Value);
      if (typeof dataValue === 'boolean') {
        filterValue = ['1', 'True', 'true'].indexOf(filterValue) >= 0 ? true
        : ['0', 'False', 'false'].indexOf(filterValue) >= 0 ? false
        : filterValue;
      }

      if (dataValue == filterValue) return true;
    }

    for (const exclude of this.activeFilter.Excluded || []) {
      const dataValue = RuntimeLayoutUtils.parseRV(data, exclude.SolutionTypeSubVariableMemberGuidId);
      let filterValue = RuntimeLayoutUtils.parseLayoutValue(exclude.Value);
      if (typeof dataValue === 'boolean') {
        filterValue = ['1', 'True', 'true'].indexOf(filterValue) >= 0 ? true
        : ['0', 'False', 'false'].indexOf(filterValue) >= 0 ? false
        : filterValue;
      }

      if (dataValue == filterValue) return false;
    }

    return this.activeFilter.Included?.length ? false : true;
  }

  private doSummaryCalculationIfEnabled(): void {
    this.uiSummaryValue = undefined;
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummary')) return;
    if (!RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummaryFieldSubMemberGuidId')) {
      this.showMissingFieldNotification('UISummaryFieldSubMemberGuidId');
      return;
    }

    const uiSummaryFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummaryFieldSubMemberGuidId');
    const uiSummaryLocationEnum = RuntimeLayoutUtils.parseRV(this.layoutControl, 'UISummaryLocation', List1DeviceControlUISummaryLocation.TopCenter);
    this.uiSummaryLocation = List1DeviceControlUISummaryLocation[uiSummaryLocationEnum].replace(/([A-Z])/g, ($1) => { return '-' + $1.toLowerCase(); }).substring(1);

    this.uiSummaryValue = 0.0;
    for (let setData of this.setDatasObjectPointers || []) {
      if ((setData as any).$uiGrouping) continue;

      let data = this.layoutScreen.datas.get(setData.objectId);
      if (!data) data = Object.assign(new RuntimeLayoutData(), { objectId: setData.objectId });
      if (this.doesDataPassActiveFilter(data)) {
        this.uiSummaryValue += RuntimeLayoutUtils.parseRV(data, uiSummaryFieldSubMemberGuidId, 0.0);
      }
    }
  }

  private getItemDesignStyle(item: any): RuntimeLayoutDesignStyle {
    if (!item) return undefined;

    const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
      return GuidUtils.isEqual((ld.originalDesignGuidId), (item.design?.guidId || item.designGuidId))
      || GuidUtils.isEqual((ld.designGuidId), (item.design?.guidId || item.designGuidId));
    });
    if (!design) return undefined;

    const designStyle = design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
      return GuidUtils.isEqual((lds.originalDesignStyleGuidId), (item.designStyle?.guidId || item.designStyleGuidId))
      || GuidUtils.isEqual((lds.designStyleGuidId), (item.designStyle?.guidId || item.designStyleGuidId));
    });
    if (designStyle && !DesignStyleJson.parseDesignStyleJson(designStyle.styleJsonBinary)) {
      this.notificationService.showNotification(new Notification({
        blocking: true,
        title: this.translateService.instant('Empty DesignStyle!'),
        text: designStyle.designStyleGuidId,
        type: RuntimeLayoutNotifyType.Unknown
      }));
    }
    return designStyle;
  }

  getDesignStyleWithMapping(i: number, j: number): RuntimeLayoutDesignStyle | null {
    const itemData = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];

    if ((itemData as any)?.$uiGrouping) { // this means it's a UIGroup
      const designStyleGuidId = this.uiGroupingMap?.[itemData.objectId.toString()]?.designStyleGuidId;
      const originalDesignStyleGuidId = this.uiGroupingMap?.[itemData.objectId.toString()]?.originalDesignStyleGuidId;
      const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
        return ld.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
          return GuidUtils.isEqual((lds.originalDesignStyleGuidId), originalDesignStyleGuidId)
          || GuidUtils.isEqual((lds.designStyleGuidId), designStyleGuidId);
        });
      });
      if (design) {
        return this.getItemDesignStyle({ designGuidId: design.originalDesignGuidId || design.designGuidId, designStyleGuidId: designStyleGuidId });
      }
    }

    if (!this.designStyleMappings?.length) return this.rowDefinition[j % this.listDefinition.rows];

    for (const designStyleMapping of this.designStyleMappings || []) {
      let groupResult = null;
      for (const filterGroup of designStyleMapping.filterStringFilter?.filterGroups || []) {
        let stepResult = this.evalFilterGroupResult(filterGroup, itemData);
        groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
      }
      if (groupResult) return this.getItemDesignStyle(designStyleMapping);
    }
    return this.rowDefinition[j % this.listDefinition.rows];
  }

  getItemData(item: DesignStyleJsonItem, i: number, j: number): RuntimeLayoutData {
    if (!this.listData?.length) return null;

    const listDataAtIndex = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];
    if (!item.field?.originalVariableGuidId) return listDataAtIndex;

    let data = null;
    if (item.field?.subVariableMemberGuidId) {
      const valueRaw = listDataAtIndex.values[item.field.subVariableMemberGuidId];
      if (valueRaw?.extendedValueType === 'Resource') {
        data = Array.from(this.layoutScreen.datas.values()).find(x => x.dataGuidId === JSON.parse(valueRaw.valueJson));
      }
    }
    if (!data && this.layoutScreen.variables) {
      const variable = Array.from(this.layoutScreen.variables?.values()).find(x => x.originalVariableGuidId === item.field.originalVariableGuidId);
      if (variable) {
        data = this.layoutScreen.datas.get(BigInt(variable.value));
      }
    }

    if (data?.isResource && !this.layoutResourceSubscriptionMap[data.resourceGuidId + '_' + data.resourceTick]) {
      // make sure we only request it once per resourceGuidId_resourceTick
      this.layoutResourceSubscriptionMap[data.resourceGuidId + '_' + data.resourceTick] = this.layoutResourceService.get(data.resourceGuidId, data.resourceTick)
      .pipe(
        mergeMap((resource: Resource) => {
          const blob: Blob = new Blob([resource.content], { type: resource.contentType });
          return BlobUtils.blobToDataURL(blob);
        })
      )
      .subscribe((dataUrl: string) => {
        this.resourceMap[data.resourceGuidId] = this.domSanitizer.bypassSecurityTrustUrl(dataUrl);
        this.cdr.markForCheck();
      });
    }

    return data;
  }

  getItemFieldValue(item: DesignStyleJsonItem, i: number, j: number): string {
    if (!item.field) return item.valueIfNull || '';

    const listDataAtIndex = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];
    if (!listDataAtIndex?.values?.size) return `[CORRUPT DATA ID: ${listDataAtIndex.objectId}]`;

    let result = item.field.staticValue != null ? item.field.staticValue
    : item.field.textId ? this.layoutTexts.get(item.field.textId)?.text
    : RuntimeLayoutUtils.parseRV(listDataAtIndex, item.field.subVariableMemberGuidId || item.field.originalVariableGuidId);

    result = result != null ? result : (item.valueIfNull || '');
    result = result.toString().replace(/\[\[([^\]]+)\]\]/g, '<i class="$1"></i>'); // replace font awesome icons in the format [[fad fa-check]]

    if (!item.cellSyntax) return result;

    // lets try to parse and execute the cellSyntax...
    let cellSyntaxResult = item.cellSyntax.slice(0);

    cellSyntaxResult = cellSyntaxResult
    .replace(/{{value}}/g, '[[value]]') // regression since the placeholder brackets changed from the original {{value}} for consistency and not conflict with @if() { }...
    .replace(/\[\[value\]\]/g, result) // replace actual value
    .replace(/"/g, '') // remove quotes
    .replace(/'/g, '') // remove quotes
    .replace(/\[\[([^\]]+)\]\]/g, '<i class="$1"></i>'); // replace font awesome icons in the format [[fad fa-check]]

    let ifMatches = null;
    let found = false;
    do {
      ifMatches = /@(if|else if)\s\(([^)]+)\)\s{([^}]+)}/gi.exec(cellSyntaxResult);
      if (!ifMatches) break;

      if (!found && ifMatches?.[2].trim() == result) {
        cellSyntaxResult = cellSyntaxResult.replace(ifMatches[0], ifMatches[3]);

        found = true;
      }
      else cellSyntaxResult = cellSyntaxResult.replace(ifMatches[0], '');
    } while (ifMatches?.length);

    const elseMatch = /@else\s{([^}]+)}/gi.exec(cellSyntaxResult);
    if (elseMatch) {
      if (!found && elseMatch.length > 1) cellSyntaxResult = cellSyntaxResult.replace(elseMatch[0], elseMatch[1]);
      else cellSyntaxResult = cellSyntaxResult.replace(elseMatch[0], '');
    }

    return cellSyntaxResult.trim();
  }

  getSetComplexDataValue(data: RuntimeLayoutData): string {
    if (!data) return '-';

    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    const setData = this.layoutScreen.sets.get(setId)?.datas?.get(data.objectId);
    if (!setData) return '-';

    const value = RuntimeLayoutUtils.parseRV(setData, this.setComplexValueName);
    return value != null ? value : '-';
  }

  setComplexDataValueAutoQuantityChange(data: RuntimeLayoutData): void {
    if (!data) return;

    if (this.activeKeyboardForData && this.keyboardService.isVisible()) return;

    this.vibrationService.vibrate();

    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    const setData = this.layoutScreen.sets.get(setId)?.datas?.get(data.objectId);
    if (!setData) return;

    let currentValue = RuntimeLayoutUtils.parseRV(setData, this.setComplexValueName);
    let updatedValue = currentValue != null ? currentValue : 0;
    updatedValue += this.lineBehaviour === QuantityList1LineBehaviour.Remove ? -1 : 1;
    if (updatedValue < 0) {
      updatedValue = 0;
      this.notificationService.showNotification(new Notification({
        blocking: false,
        title: this.translateService.instant('Verification'),
        text: RuntimeLayoutUtils.parseRV(this.layoutControl, 'QuantityScanNoQuantityMessage') || this.translateService.instant('No quantity!'),
        type: RuntimeLayoutNotifyType.VerificationAlert
      }));
      this.cdr.markForCheck();
      return;
    }

    this.addToUndoStack();

    if (setData.setComplexDataValues.has(this.setComplexValueName)) {
      setData.setComplexDataValues.get(this.setComplexValueName).valueJson = updatedValue.toString();
    } else {
      setData.setComplexDataValues = Object.assign(
        new Map<string, RuntimeLayoutValue | null>(),
        setData.setComplexDataValues,
      );
      setData.setComplexDataValues.set(this.setComplexValueName, Object.assign(new RuntimeLayoutValue(), {
        valueJson: updatedValue.toString(),
        valueTypeId: RuntimeLayoutValueType.Double,
      }));
    }
    (setData as any).$isDirty = true;

    this.updateQuantityChangeCorrespondingDataValue(data, updatedValue);
    this.blink(data);
    this.cdr.markForCheck();

    const triggerValue = RuntimeLayoutUtils.parseRV(setData, this.setComplexTriggerValueName);
    if (triggerValue != null && triggerValue == updatedValue) {
      this.clearUndoStack();
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerPortEvent('QuantityUpdate');
        }
      );
      return;
    }

    const allComplete = !Array.from(this.layoutScreen.sets.get(setId)?.datas?.values() || [])
    .some((sd: RuntimeLayoutSetData) => {
      return RuntimeLayoutUtils.parseRV(sd, this.setComplexValueName, 0);
    });
    if (allComplete) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerPortEvent('AllComplete');
        }
      );
    }
  }

  setComplexDataValueManualQuantityChange(data: RuntimeLayoutData, discard?: boolean): void {
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    const setData = this.layoutScreen.sets.get(setId)?.datas?.get(data.objectId);
    if (!setData) return;
    if (this.activeKeyboardForData && this.activeKeyboardForData !== data) {
      return; // or // this.setComplexDataValueManualQuantityChange(this.activeKeyboardForData);
    }

    if (this.keyboardService.isVisible()) {
      const originalValue = RuntimeLayoutUtils.parseLayoutValue(this.originalSetDatas[data.objectId.toString()]?.setComplexDataValues?.get(this.setComplexValueName), this.getQuantityChangeCorrespondingDataValue(data));
      if (
        this.lineBehaviour === QuantityList1LineBehaviour.Remove &&
        RuntimeLayoutUtils.parseLayoutValue(setData.setComplexDataValues.get(this.setComplexValueName)) > originalValue
      ) {
        discard = true; // we don't allow values higher than the current quantity
        this.notificationService.showNotification(new Notification({
          blocking: false,
          title: this.translateService.instant('Verification'),
          text: this.translateService.instant('Not allowed quantity!'),
          type: RuntimeLayoutNotifyType.VerificationAlert
        }));
      }
      if (discard) {
        setData.setComplexDataValues.get(this.setComplexValueName).valueJson = originalValue;
      } else {
        // we need to add to the undo stack the original value...so we just go back and forth with the value here...
        const updatedValue = RuntimeLayoutUtils.parseLayoutValue(setData.setComplexDataValues.get(this.setComplexValueName));
        setData.setComplexDataValues.get(this.setComplexValueName).valueJson = originalValue;
        this.addToUndoStack();
        setData.setComplexDataValues.get(this.setComplexValueName).valueJson = updatedValue;

        (setData as any).$isDirty = true;
      }
      this.updateQuantityChangeCorrespondingDataValue(data, RuntimeLayoutUtils.parseLayoutValue(setData.setComplexDataValues.get(this.setComplexValueName)));

      this.activeKeyboardForData = undefined;

      this.layoutScreen.backButton = this.originalBackButton;
      this.layoutScreen.forwardButton = this.originalForwardButton;
      this.layoutScreen.renderValues.set(
        'ButtonsType',
        Object.assign(new RuntimeLayoutValue(), { valueJson: this.originalButtonsType.toString(), valueTypeId: RuntimeLayoutValueType.Int })
      );
      this.layoutScreenChange.emit(this.layoutScreen);
      this.keyboardService.hide();

      this.cdr.markForCheck();
      return;
    }

    this.activeKeyboardForData = data;

    this.layoutScreen.backButton = true;
    this.layoutScreen.forwardButton = true;
    this.layoutScreen.renderValues.set(
      'ButtonsType',
      Object.assign(new RuntimeLayoutValue(), { valueJson: '1', valueTypeId: RuntimeLayoutValueType.Int })
    );
    this.layoutScreenChange.emit(this.layoutScreen);

    let newValue = '';
    this.keyboardService.show(
      KeyboardType.Numeric,
      false,
      null,
      (key: string) => {
        if (key === 'Backspace') {
          if (newValue.length > 0) newValue = newValue.substring(0, newValue.length - 1);
        } else if (key === '.' && newValue.length === 0) {
          newValue = '0.';
        } else if (key === '.' && newValue.length > 0 && newValue.indexOf('.') < 0) {
          newValue += key;
        } else if (key !== '.') {
          if (newValue === '0') newValue = key;
          else newValue += key;
        }

        if (newValue.length > 0 && newValue.indexOf('.') === newValue.length - 1) return;

        setData.setComplexDataValues.get(this.setComplexValueName).valueJson = newValue.toString();
        this.cdr.markForCheck();
      }
    );
  }

  addToUndoStack() {
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');

    this.undoStackLayoutDatas = this.undoStackLayoutDatas || [];
    const undoDatas = new Map<bigint, RuntimeLayoutData>();
    for (const key of this.layoutScreen.datas.keys()) {
      undoDatas.set(key, Object.assign(new RuntimeLayoutData(), structuredClone(this.layoutScreen.datas.get(key))));
    }
    this.undoStackLayoutDatas.push(undoDatas);
    this.undoStackLayoutSet = this.undoStackLayoutSet || [];
    this.undoStackLayoutSet.push(Object.assign(new RuntimeLayoutSet(), structuredClone(this.layoutScreen.sets.get(setId))));
  }

  private clearUndoStack() {
    this.undoStackLayoutDatas = [];
    this.undoStackLayoutSet = [];
    this.cdr.markForCheck();
  }

  undo() {
    if (!this.undoStackLayoutSet?.length) return;
    if (this.activeKeyboardForData && this.keyboardService.isVisible()) return;

    this.vibrationService.vibrate();

    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    this.layoutScreen.sets.set(setId, this.undoStackLayoutSet.pop());
    this.layoutScreen.datas.clear();
    const undoLayoutDatas = this.undoStackLayoutDatas.pop();
    for (const layoutDatas of Array.from(undoLayoutDatas.values())) {
      this.layoutScreen.datas.set(layoutDatas.objectId, layoutDatas);
    };


    this.updateUpdateContext();

    this.listData = [];
    this.numOfVisibleItems = this.populateListDataFromTo(0, this.numOfVisibleItems);
    this.doSummaryCalculationIfEnabled();
    this.pipePureValueBusting++;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.gridsterComponents.forEach(g => g.onResize());
      this.cdr.markForCheck();
    }, 25);
  }

  private getQuantityChangeCorrespondingDataValue(data: RuntimeLayoutData): any {
    const setComplexUpdateFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SetComplexUpdateFieldSubMemberGuidId', null);
    if (!setComplexUpdateFieldSubMemberGuidId) return;

    return RuntimeLayoutUtils.parseRV(data, setComplexUpdateFieldSubMemberGuidId);
  }

  private updateQuantityChangeCorrespondingDataValue(data: RuntimeLayoutData, updatedValue: any): void {
    this.updateUpdateContext();

    const setComplexUpdateFieldSubMemberGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SetComplexUpdateFieldSubMemberGuidId', null);
    if (!setComplexUpdateFieldSubMemberGuidId) return; // this seems to be '00000000000000000000000000000000' when not set, so it won't ever return here...

    const value: RuntimeLayoutValue = data.values?.get(setComplexUpdateFieldSubMemberGuidId);
    if (!value) return;

    value.valueJson = updatedValue.toString();

    this.listData = [];
    this.numOfVisibleItems = this.populateListDataFromTo(0, this.numOfVisibleItems);
    this.doSummaryCalculationIfEnabled();
    this.pipePureValueBusting++;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.gridsterComponents.forEach(g => g.onResize());
      this.cdr.markForCheck();
    }, 25);
  }

  private evalFilterGroupResult(outterfilterGroup: any, itemData: RuntimeLayoutData): boolean {
    if (!outterfilterGroup.filter?.filterGroups?.length) {
      return this.evalFilterResult(outterfilterGroup.filter, itemData);
    }

    let groupResult = null;
    for (const filterGroup of outterfilterGroup.filter?.filterGroups || []) {
      let stepResult = this.evalFilterResult(filterGroup.filter, itemData);
      groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
    }
    return groupResult;
  }

  private evalFilterResult(filter: any, itemData: RuntimeLayoutData) {
    if (!filter || !itemData?.values) return null;

    let stepResult = null;
    const filterValue = RuntimeLayoutUtils.parseRV(itemData, GuidUtils.clean(filter.member.guidId));
    switch (filter.operator) {
      case '=':
        stepResult = filter.value == filterValue;
        break;
      case '!=':
        stepResult = filter.value != filterValue;
        break;
      case '<':
        stepResult = filter.value < filterValue;
        break;
      case '<=':
        stepResult = filter.value <= filterValue;
        break;
      case '>':
        stepResult = filter.value > filterValue;
        break;
      case '>=':
        stepResult = filter.value >= filterValue;
        break;
    }
    return stepResult;
  }

  protected advanceCallback(scan: Scan, plugin?: BasePlugin) {
    const quantityScanBehaviour = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'QuantityScanBehaviour', QuantityList1ScanBehaviour.None));
    const quantityScanEnabled = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'QuantityScanEnabled', false));
    const scanItemSearchMemberGuidIds = RuntimeLayoutUtils.parseRV(this.layoutControl, 'ScanItemSearchMemberGuidIds', '');
    if (!quantityScanEnabled || !scanItemSearchMemberGuidIds) return super.advanceCallback(scan, plugin);

    const filteredDatas = (this.setDatasObjectPointers || [])
    .map((setData: RuntimeLayoutSetData) => {
      return this.layoutScreen.datas.get(setData.objectId);
    })
    .filter((data: RuntimeLayoutData) => {
      return !(data as any).$uiGrouping &&
      (scanItemSearchMemberGuidIds.split(',') || []).some(searchMemberGuidId => data.values[searchMemberGuidId]?.parse() == scan.value);
    });
    if (filteredDatas.length) {
      this.ngZone.run(() => {
        if (plugin) plugin.action({ command: 'stop' }).subscribe();
        if (quantityScanBehaviour === QuantityList1ScanBehaviour.None) { // select item
            LogUtils.log('scanner.advanceCallback() - list item click:', filteredDatas[0]);
            this.itemClick(filteredDatas[0], null, 'Item');
        } else { // quantityChange
          if (this.lineBehaviour === QuantityList1LineBehaviour.Remove) {
            const firstNonZeroData = filteredDatas.find((data: any) => {
              const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
              const setData = this.layoutScreen.sets.get(setId)?.datas?.get(data.objectId);
              if (!setData) return false;

              let currentValue = RuntimeLayoutUtils.parseRV(setData, this.setComplexValueName);
              return !!currentValue;
            })
            LogUtils.log('scanner.advanceCallback() - list item quantityChange:', firstNonZeroData || filteredDatas[0]);
            this.scrollIntoView(firstNonZeroData || filteredDatas[0]);
            this.setComplexDataValueAutoQuantityChange(firstNonZeroData || filteredDatas[0]);
          } else {
            LogUtils.log('scanner.advanceCallback() - list item quantityChange:', filteredDatas[0]);
            this.scrollIntoView(filteredDatas[0]);
            this.setComplexDataValueAutoQuantityChange(filteredDatas[0]);
          }
        }
      });
      return;
    }

    // try to search the list items to see if we get a match...
    for (let setData of this.setDatasObjectPointers || []) {
      let data = this.layoutScreen.datas.get(setData.objectId);
      for (const searchMemberGuidId of scanItemSearchMemberGuidIds.split(',') || []) {
        if (data.values[searchMemberGuidId]?.parse() != scan.value) continue;

        this.ngZone.run(() => {
          if (plugin) plugin.action({ command: 'stop' }).subscribe();
          if (quantityScanBehaviour === QuantityList1ScanBehaviour.None) { // select item
            LogUtils.log('scanner.advanceCallback() - list item click:', data);
            this.itemClick(data, null, 'Item');
          } else { // quantityChange
            LogUtils.log('scanner.advanceCallback() - list item quantityChange:', data);
            this.scrollIntoView(data);
            this.setComplexDataValueAutoQuantityChange(data);
          }
        });
        return;
      }
    }

    // scanned item was not found, so, either trigger Update port, or just do normal scan event
    if ((this.updateContextArray || []).length > 0) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          super.advanceCallback(scan, plugin);
        }
      );
    } else {
      super.advanceCallback(scan, plugin);
    }
  }

  private clearSetDatasIsDirty() {
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let setData: RuntimeLayoutSetData = this.setDatasObjectPointers[i];
      delete (setData as any).$isDirty;
    }
  }

  private updateUpdateContext() {
    this.updateContextArray = [];
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let setData: RuntimeLayoutSetData = this.setDatasObjectPointers[i];
      if (!(setData as any).$isDirty) continue;
      let data: RuntimeLayoutData = this.layoutScreen.datas.get(this.setDatasObjectPointers[i].objectId);
      if (!data) continue;

      const values = {
        [this.setComplexValueName]: RuntimeLayoutUtils.parseRV(setData, this.setComplexValueName),
      };
      if (this.setComplexTriggerValueName) values[this.setComplexTriggerValueName] = RuntimeLayoutUtils.parseRV(setData, this.setComplexTriggerValueName);

      this.updateContextArray.push({
        objectId: data.objectId,
        runtimeObjectId: data.runtimeDataObjectId,
        values: values,
      });
    }
  }

  backButtonOverride(): boolean {
    if (this.activeKeyboardForData && this.keyboardService.isVisible()) {
      this.setComplexDataValueManualQuantityChange(this.activeKeyboardForData, true);
      return true;
    }

    if ((this.updateContextArray || []).length > 0 && !this.ignoreButtonOverride) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.BackButton });
        }
      );
      return true;
    }

    this.ignoreButtonOverride = false;
    return false;
  }

  forwardButtonOverride(): boolean {
    if (this.activeKeyboardForData && this.keyboardService.isVisible()) {
      this.setComplexDataValueManualQuantityChange(this.activeKeyboardForData, false);
      return true;
    }

    if ((this.updateContextArray || []).length > 0 && !this.ignoreButtonOverride) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
        }
      );
      return true;
    }

    this.ignoreButtonOverride = false;
    return false;
  }

  preActionTrigger(): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      if ((this.updateContextArray || []).length > 0) {
        this.triggerPortEvent(
          'Update',
          () => {
            this.clearSetDatasIsDirty();
            observer.next(null);
            observer.complete();
          }
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  itemClick(data: RuntimeLayoutData, designStyle?: RuntimeLayoutDesignStyle, portName?: string) {
    if (!this.useItem) return;
    if (Object.keys(data?.values || {}).length === 0) return;
    if ((data as any)?.$uiGrouping) return;
    if (designStyle?.notClickable) return;
    if (this.activeKeyboardForData && this.keyboardService.isVisible()) return;

    this.vibrationService.vibrate();
    this.clickedData = data;

    if ((this.updateContextArray || []).length > 0) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this._itemClick(data, designStyle, portName);
        }
      );
      return;
    }

    this._itemClick(data, designStyle, portName);
  }

  private _itemClick(data: RuntimeLayoutData, designStyle?: RuntimeLayoutDesignStyle, portName?: string) {
    const grouping = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Grouping', false);

    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('EventCode', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify('ListItem'),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: portName ? JSON.stringify(portName)
      : grouping ? JSON.stringify('Items')
      : JSON.stringify('Item'),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    this.triggerEvent.emit({
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

  private triggerPortEvent(portName: 'AllComplete' | 'QuantityUpdate' | 'Update', callback?: () => void) {
    const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
    eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    if (portName === 'Update' && !this.useUpdate) {
      this.ignoreButtonOverride = true;
      if (callback) callback();
      return;
    }

    this.triggerEvent.emit({
      callback: (result: boolean) => {
        if (!result) {
          this.updateUpdateContext();
          return;
        }

        if (callback) callback();
      },
      eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const setId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'Set');
    const set = this.layoutScreen.sets.get(setId);
    let context = new Map<string, RuntimeLayoutValue | null>();

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    if ((this.updateContextArray || []).length > 0) {
      context.set('SetObjectId', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(set?.objectId || 0),
        valueTypeId: RuntimeLayoutValueType.String
      }));
      context.set('RuntimeSetObjectId', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(set?.runtimeSetObjectId || 0),
        valueTypeId: RuntimeLayoutValueType.String
      }));
      context.set('Update', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.updateContextArray)),
        valueTypeId: RuntimeLayoutValueType.String
      }));
      this.updateContextArray = null;
      if (this.useUpdate) return context;
    }

    if (!this.clickedData) return context;

    context = context || new Map<string, RuntimeLayoutValue | null>();
    context.set('SourceRuntimeObjectId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(set?.runtimeSetObjectId || 0),
      valueTypeId: RuntimeLayoutValueType.Int
    }));
    context.set('ItemRuntimeObjectId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.clickedData.runtimeDataObjectId),
      valueTypeId: RuntimeLayoutValueType.Int
    }));
    context.set('ItemEnvironmentGuidId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.clickedData.environmentGuidId),
      valueTypeId: RuntimeLayoutValueType.String
    }));
    context.set('ItemGuidId', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(this.clickedData.dataGuidId),
      valueTypeId: RuntimeLayoutValueType.String
    }));

    return context;
  }

  getNgForArray(repetitions: number) {
    if (repetitions > ~~repetitions) {
      repetitions = ~~repetitions + 1; // round up if required...
    }
    return Array(repetitions).fill(1).map((x,i) => i + 1);
  }

  doInfinite(event: any) {
    setTimeout(() => {
      this.numOfVisibleItems = this.populateListDataFromTo(
        this.numOfVisibleItems,
        this.numOfVisibleItems + (this.listDefinition.screenRows || this.defaultScreenRows) - 1
      );

      event.target.complete();
      this.cdr.markForCheck();
    }, 10);
  }

  getMinRowHeight(rowHeightPercentage: number | string, minRem: number | string) {
    return Math.max(
      this.convertRowHeightPercentageToPixels(rowHeightPercentage),
      this.convertRemToPixels(minRem),
    ) + 'px'
  }

  private scrollIntoView(data: RuntimeLayoutData) {
    let dataEl = this.el.nativeElement.querySelector('#objectId_' + data.objectId) as HTMLElement;
    if (!dataEl) {
      const setDataIndex = this.setDatasObjectPointers.findIndex(x => !(x as any).$uiGrouping && x.objectId === data.objectId);
      if (setDataIndex < this.numOfVisibleItems) return;

      this.numOfVisibleItems = this.populateListDataFromTo(
        this.numOfVisibleItems,
        setDataIndex,
      );
      this.cdr.markForCheck();

      setTimeout(() => {
        this.scrollIntoView(data);
      }, 100);
      return;
    }

    dataEl.scrollIntoView({ behavior: 'smooth', block: 'center' });

    this.blink(data);
  }

  private blink(data: RuntimeLayoutData) {
    setTimeout(() => {
      let dataEl = this.el.nativeElement.querySelector('#objectId_' + data.objectId) as HTMLElement;
      if (!dataEl) return;

      dataEl.parentElement.className += ' blink'
      this.cdr.markForCheck();

      setTimeout(() => {
        dataEl.parentElement.className = dataEl.parentElement.className.replace(/blink/g, '');
        this.cdr.markForCheck();
      }, 2 * 1000);
    }, 100)
  }

  private convertRowHeightPercentageToPixels(rowHeightPercentage: number | string) {
    return (parseFloat(rowHeightPercentage.toString()) / 100) * parseFloat(getComputedStyle(this.el.nativeElement).height);
  }

  private convertRemToPixels(rem: number | string) {
    return parseFloat(rem.toString()) * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

}

