import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgProgressbar } from 'ngx-progressbar';
import { from } from 'rxjs';
import { HybridDataSyncronizationState } from 'src/app/shared/models/layout-state.model';
import { SynchronizeHybridDataStateResult } from 'src/app/shared/models/manager/synchronize-hybrid-data-state-result.model';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { LogUtils } from 'src/app/shared/utils';
import { ControlBaseComponent } from '../base/control-base.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,
    NgProgressbar,
  ],
  selector: 'lc-control-synchronize-hybrid-data-state',
  templateUrl: 'control-synchronize-hybrid-data-state.component.html',
  styleUrls: ['./control-synchronize-hybrid-data-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlSynchronizeHybridDataStateComponent extends ControlBaseComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild(NgProgressbar, { static: true }) progressBar: NgProgressbar;

  initialQueueCount: number;
  isActive: boolean;
  isRefreshing: boolean;
  theme: string;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngAfterViewInit() {
    this.progressBar.start();
    this.setValue(0);
  }

  ngOnInit() {
    this.isActive = true;
    this.refresh();
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  refresh() {
    if (this.isRefreshing) return;
    this.isRefreshing = true;

    const mobileEngineLayoutState = this.appService.getMobileEngineLayoutState();
    this.initialQueueCount = mobileEngineLayoutState?.queueCount || 1;
    this.updateProgressbar();

    const windowAny: any = window;
    from(windowAny.mobileEngine.instance.invokeMethodAsync('HybridDeviceControlSynchronizeHybridDataStateAsync', mobileEngineLayoutState?.hybridDataSyncronizationState || HybridDataSyncronizationState.Ready, 3))
    .subscribe({
      next: (result: SynchronizeHybridDataStateResult) => {
        this.isRefreshing = false;
        if (!result) return;

        LogUtils.log(result.debugText);
        let portName = 'Error';
        if (result.success) {
          portName = result.resultPort;
        } else if (result.timeout) {
          portName = 'Timeout';
        } else if (result.wait) {
          setTimeout(() => {
            this.refresh();
          },result.waitInMilliseconds || 100);
          return;
        } else {
          portName = 'Error';
        }

        const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
        eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
          valueJson: JSON.stringify(portName),
          valueTypeId: RuntimeLayoutValueType.String,
        }));
        this.triggerEvent.emit({
          eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
          platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
        });

        this.cdr.markForCheck();
      },
      error: (error: any) => {
        this.isRefreshing = false;
        this.cdr.markForCheck();
      }
    });
    this.cdr.markForCheck();
  }

  private updateProgressbar() {
    setTimeout(() => {
      if (!this.isActive) return;

      const mobileEngineLayoutState = this.appService.getMobileEngineLayoutState();
      if (mobileEngineLayoutState) {
        this.setValue((this.initialQueueCount - mobileEngineLayoutState.queueCount) * 100 / this.initialQueueCount);
      }

      this.updateProgressbar();
    }, 25);
  }

  setValue(value: number) {
    if (value == null) return;
    if (!this.progressBar) return;

    this.progressBar.set(value);
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    return context;
  }

}

