import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import * as json2xml from 'json2xml';
import { Observable, from, of, throwError, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ClientManager3EnrollmentNewRequest } from 'src/app/shared/models/memorypack/ClientManager3EnrollmentNewRequest';
import { ClientManager3EnrollmentNewResponse } from 'src/app/shared/models/memorypack/ClientManager3EnrollmentNewResponse';
import { ClientManager3EnrollmentRefreshRequest } from 'src/app/shared/models/memorypack/ClientManager3EnrollmentRefreshRequest';
import { ClientManager3EnrollmentRefreshResponse } from 'src/app/shared/models/memorypack/ClientManager3EnrollmentRefreshResponse';
import { DeviceEnrollment3 } from 'src/app/shared/models/memorypack/DeviceEnrollment3';
import { BrowserUtils, LogUtils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { HostOemRequest } from '../../../models';
import { ClientType } from '../../../models/manager/client-type.enum';
import { TranslateService } from '../../app';
import { StorageService } from '../../app/storage.service';
import { TextService } from '../../text/text.service';
import { ApiService } from '../api.service';
import { ThemeService } from '../../theme/theme.service';


@Injectable({
  providedIn: 'root'
})
export class EnrollService {

  private readonly urlSuffixPlaceholder = 'enrollment/{how}';
  private readonly storageKey = 'lc_deviceEnrollment';
  private readonly storageKeyResources = 'lc_resources';

  constructor(
    private apiService: ApiService,
    private device: Device,
    private file: File,
    private storageService: StorageService,
    private textService: TextService,
    private themeService: ThemeService,
    private translateService: TranslateService,
  ) { }

  oem(deviceOemGuidId: string, runClientType: ClientType): Observable<DeviceEnrollment3> {
    return this.apiService.post<DeviceEnrollment3>(
      this.urlSuffixPlaceholder.replace('{how}', 'oem'),
      {
        runClientType: runClientType,
        hardwareOs: this.device.platform || '1',
        uUID: this.device.uuid || '',
        deviceOemGuidId: deviceOemGuidId,
      } as HostOemRequest
    )
    .pipe(
      mergeMap((response: any) => {
        const deviceEnrollment = response ? response.runDeviceEnrollment : null;
        if (!deviceEnrollment) return of(null);

        deviceEnrollment.$runCode = undefined;
        deviceEnrollment.$runDevice = undefined;
        deviceEnrollment.$deviceOemGuidId = deviceOemGuidId;
        deviceEnrollment.$enrollmentKey = undefined;

        // this.textService.languageGuidId = response.devicePlatformTexts3.languageGuidId;
        // this.textService.texts = response.devicePlatformTexts3.texts as any;

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return deviceEnrollment;
          })
        );
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment3) => {
        return this.writeEnrollmentToFile(deviceEnrollment);
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment3) => {
        return this.localUpdate(deviceEnrollment);
      })
    );
  }

  new(
    enrollmentRunUri: string,
    enrollmentKey: string,
    enrollmentPassword: string,
    enrollmentSubKey: string,
    runClientType: ClientType,
  ): Observable<ClientManager3EnrollmentNewResponse> {
    let request = null;
    const dto: ClientManager3EnrollmentNewRequest = {
      clientType: runClientType,
      hardwareOs: this.device.platform || '1',
      uUID: this.device.uuid || BrowserUtils.getQueryParams()?.runDevice || '',
      enrollmentKey: enrollmentKey,
      enrollmentPassword: enrollmentPassword,
      enrollmentSubKey: enrollmentSubKey,
    } as any;

    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'hybrid') {
      request = from(windowAny.mobileEngine.instance.invokeMethodAsync('ClientManager3EnrollmentNewAsync', dto))
    } else {
      request = this.apiService.post<ClientManager3EnrollmentNewResponse>(
        this.urlSuffixPlaceholder.replace('{how}', 'new'),
        dto,
        enrollmentRunUri
      )
    }

    return request.pipe(
      mergeMap((response: ClientManager3EnrollmentNewResponse) => {
        const deviceEnrollment: DeviceEnrollment3 = response?.deviceEnrollment3;
        if (!deviceEnrollment || !response?.enrolled) return of(response);

        this.apiService.setApiUrl(enrollmentRunUri, enrollmentRunUri === environment.apiUrl);

        deviceEnrollment.$runCode = undefined;
        deviceEnrollment.$runDevice = BrowserUtils.getQueryParams()?.runDevice;
        deviceEnrollment.$deviceOemGuidId = undefined;
        deviceEnrollment.$enrollmentKey = enrollmentKey;

        this.textService.languageGuidId = response.devicePlatformTexts3.languageGuidId;
        this.textService.texts = response.devicePlatformTexts3.texts as any;

        if (deviceEnrollment.customClientSkin) {
          this.themeService.overrideThemesWithClientSkins(Array.from(deviceEnrollment.clientSkinStyles.values()));
        }

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return response;
          })
        );
      }),
      mergeMap((response: ClientManager3EnrollmentNewResponse) => {
        const deviceEnrollment: any = response?.deviceEnrollment3;
        if (!deviceEnrollment || !response?.enrolled) return of(response);

        return this.writeEnrollmentToFile(deviceEnrollment)
        .pipe(
          map(() => {
            return response;
          })
        );
      }),
      mergeMap((response: ClientManager3EnrollmentNewResponse) => {
        const deviceEnrollment: any = response?.deviceEnrollment3;
        if (!deviceEnrollment || !response?.enrolled) return of(response);

        return this.localUpdate(deviceEnrollment)
        .pipe(
          map(() => {
            return response;
          })
        );
      }),
    );
  }

  list(): Observable<DeviceEnrollment3> {
    return this.apiService.post<DeviceEnrollment3>(
      this.urlSuffixPlaceholder.replace('{how}', 'list'),
      {}
    );
  }

  refresh(currentDeviceEnrollment: DeviceEnrollment3): Observable<DeviceEnrollment3> {
    let request = null;
    const dto: ClientManager3EnrollmentRefreshRequest = {
      enrollmentGuidId: currentDeviceEnrollment.enrollmentGuidId,
      environmentGuidId: currentDeviceEnrollment.environmentGuidId,
    } as any;

    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'hybrid') {
      request = from(windowAny.mobileEngine.instance.invokeMethodAsync('ClientManager3EnrollmentRefreshAsync', dto))
    } else {
      request = this.apiService.post(
        this.urlSuffixPlaceholder.replace('{how}', 'refresh'),
        dto,
      )
    }

    return request.pipe(
      mergeMap((response: ClientManager3EnrollmentRefreshResponse) => {
        if (!response?.validEnrollment) {
          return throwError(() => new Error(response?.systemExceptionText || this.translateService.instant('Invalid Enrollment')));
        } else if (response?.noDeviceEngine) {
          return throwError(() => new Error(response?.systemExceptionText || this.translateService.instant('No Device Engine')));
        } else if (response?.systemException) {
          return throwError(() => new Error(response?.systemExceptionText || this.translateService.instant('Unknown error')));
        }

        const deviceEnrollment: DeviceEnrollment3 = response?.deviceEnrollment3;
        deviceEnrollment.$deviceRunStatus = currentDeviceEnrollment.$deviceRunStatus;
        deviceEnrollment.$deviceOemGuidId = currentDeviceEnrollment.$deviceOemGuidId;
        deviceEnrollment.$enrollmentKey = currentDeviceEnrollment.$enrollmentKey;
        deviceEnrollment.$runCode = currentDeviceEnrollment.$runCode;
        deviceEnrollment.$runSet = currentDeviceEnrollment.$runSet;
        deviceEnrollment.$runSetSubKey = currentDeviceEnrollment.$runSetSubKey;

        this.textService.languageGuidId = response.devicePlatformTexts3.languageGuidId;
        this.textService.texts = response.devicePlatformTexts3.texts as any;

        if (deviceEnrollment.customClientSkin) {
          this.themeService.overrideThemesWithClientSkins(Array.from(deviceEnrollment.clientSkinStyles.values()));
        }

        return this.storageService.get(this.storageKeyResources)
        .pipe(
          mergeMap((resourceGuidIds: string[]) => {
            resourceGuidIds = resourceGuidIds || [];
            return zip(
              this.storageService.remove(this.storageKeyResources),
              ...resourceGuidIds.map((resourceGuidId: string) => {
                return this.storageService.remove(this.storageKeyResources + '_' + resourceGuidId)
              })
            );
          }),
          map(() => {
            return deviceEnrollment;
          })
        );
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment3) => {
        return this.writeEnrollmentToFile(deviceEnrollment);
      }),
      mergeMap((deviceEnrollment: DeviceEnrollment3) => {
        return this.localUpdate(deviceEnrollment);
      })
    );
  }

  localUpdate(deviceEnrollment: DeviceEnrollment3): Observable<DeviceEnrollment3> {
    return this.storageService.set(this.storageKey, deviceEnrollment)
    .pipe(
      map(() => {
        return deviceEnrollment;
      })
    );
  }

  getLocalEnrollment(): Observable<DeviceEnrollment3> {
    return this.storageService.get(this.storageKey);
  }

  private writeEnrollmentToFile(de: DeviceEnrollment3): Observable<DeviceEnrollment3> {
    if (!BrowserUtils.isDeviceApp()) return of(de);
    if (!de) return of(de);
    if (!de?.deviceIndustrialMode) return of(de);

    const rootDir = 'file:///sdcard/';
    const dirName = 'Documents/';
    const filePath = rootDir + dirName;
    const fileName = 'enrollment.xml';


    LogUtils.log('Writing enrollment info to: ' + filePath + fileName);
    const enrollmentXml = json2xml(
      {
        enrollment: {
          deviceGuidId: de.deviceGuidId,
          deviceId: de.deviceId,
          deviceIndustrialMode: de.deviceIndustrialMode,
          deviceLicensePaused: de.deviceLicensePaused,
          deviceSolutionGuidId: de.deviceSolutionGuidId,
          deviceSolutionName: de.deviceSolutionName,
          deviceSolutionSetGuidId: de.deviceSolutionSetGuidId,
          deviceSolutionSetName: de.deviceSolutionSetName,
          enrollmentGuidId: de.enrollmentGuidId,
          environmentGuidId: de.environmentGuidId,
          environmentName: de.environmentName,
          hardwareGuidId: de.hardwareGuidId,
          hasDeviceLicense: de.hasDeviceLicense,
          siteName: de.siteName,
          siteGuidId: de.siteGuidId,
          sitePath: de.sitePath,
          sotiSiteKey: de.sotiSiteKey,
          solutionAgreementGuidId: de.solutionAgreementGuidId,
          solutionAgreementName: de.solutionAgreementName,
          solutionProfileName: de.solutionProfileName,
        }
      },
      { header: true }
    );

    return from(
      this.file.checkFile(filePath, fileName)
    ).pipe(
      catchError((error: any) => {
        // The Documents directory only exists, by default, in Android 11+
        return from(this.file.checkDir(rootDir, dirName))
        .pipe(
          catchError((error: any) => {
            return from(this.file.createDir(rootDir, dirName, false));
          }),
          map((result: any) => {
            return true;
          })
        );
      }),
      mergeMap((result: boolean) => {
        return this.file.writeFile(filePath, fileName, enrollmentXml, { replace: result });
      }),
      map(() => {
        LogUtils.log('File enrollment.xml updated successfully.');
        return de;
      }),
      catchError((error: any) => {
        alert('Failed to write to ' + filePath + fileName + '. Please delete the file manually and refresh the enrollment in System Info.');
        return of(de);
      }),
    );
  }

}
