export class DateUtils {

  static nowAsISOString() {
    return (new Date()).toISOString();
  }

  static nowAsLogString() {
    // return this.nowAsISOString().substring(0, 19).replace('T', ' ');
    return this.nowAsISOString().substring(11, 23);
  }

}
