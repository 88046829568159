import { ChangeDetectionStrategy, Component, Injector, OnInit, ChangeDetectorRef } from '@angular/core';
import { DictString } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';
import { JsonUtils, CaseUtils } from 'src/app/shared/utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { PlatformResource } from 'src/app/shared/models/platform-resource.model';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { HybridDataSyncronizationState, HybridEventQueueSecurityLevel, HybridState, LayoutState } from 'src/app/shared/models/layout-state.model';


@Component({
  selector: 'lc-control-mainmenu1',
  templateUrl: 'control-mainmenu1.component.html',
  styleUrls: ['./control-mainmenu1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlMainMenu1Component extends ControlBaseComponent implements OnInit {

  numOfCols: number;
  numOfScreenRows: number;
  menuButtons: any[];
  mobileEngineLayoutState: LayoutState;
  private menuDefinition: any;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.subscriptions.push(
      this.appService.listenToMobileEngineLayoutState()
      .subscribe((layoutState: LayoutState) => {
        this.mobileEngineLayoutState = layoutState;
        this.refreshButtonsWithMobileEngineLayoutState();
        this.cdr.markForCheck();
      }),
    );
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    if (!this.layoutControl || !this.layoutScreen) return;

    const buttonCount = RuntimeLayoutUtils.parseRV(this.layoutControl, 'm.Count', 0);

    // get the active menu buttons (flow buttons, json created by patrik, hence the toCamel())
    const menuJson = CaseUtils.toCamel(JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'MenuJson', null)));
    const activeFlowButtons = (menuJson?.buttons || [])
    .map((b: any) => {
      b.icon = PlatformResource.resourceMap[b.resourceId];
      b.label = b.text;

      return b;
    });

    // Get the menu definition, if any (from the studio editor)
    this.menuDefinition = JSON.parse(RuntimeLayoutUtils.parseRV(this.layoutControl, 'MenuStyleJson', null));
    if (this.menuDefinition) {
      const allFlatButtons = [];
      // iterate over the all the menuStyleJson buttons and child buttons and remove the inactive ones
      for (let i = 0; i < this.menuDefinition.items?.length; i++) {
        const item = this.menuDefinition.items[i];
        if (!item.isGroup) {
          const exists = activeFlowButtons.find((x: any) => {
            return GuidUtils.isEqual(x.guidId, item.guidId);
          });
          if (exists) {
            item.label = exists.label;
            allFlatButtons.push(item);
          } else {
            this.menuDefinition.items.splice(i, 1);
            i--;
          }
        } else {
          for (let j = 0; j < item.items?.length; j++) {
            const childItem = item.items[j];
            const exists = activeFlowButtons.find((x: any) => {
              return GuidUtils.isEqual(x.guidId, childItem.guidId);
            });
            if (exists) {
              childItem.label = exists.label;
              allFlatButtons.push(childItem);
            } else {
              item.items.splice(j, 1);
              j--;
            }
          }

          if (!item.items?.length) {
            this.menuDefinition.items.splice(i, 1);
            i--;
          }
        }
      }

      // check if there are any missing buttons in the studio menu definition, and add them at the end.
      for (const item of activeFlowButtons) {
        const exists = allFlatButtons.find((x: any) => {
          return GuidUtils.isEqual(x.guidId, item.guidId);
        });
        if (exists) continue;

        this.menuDefinition.items.push(item);
      }

      this.numOfCols = this.menuDefinition.cols || 2;
      this.numOfScreenRows = this.menuDefinition.numOfScreenRows;
      this.menuButtons = this.menuDefinition.items;
    } else {
      this.numOfCols = 2;
      this.numOfScreenRows = undefined;
      this.menuButtons = activeFlowButtons;
    }

    // MenuJson.ResourceId was not being correctly populated in runEngine < 2.0.7, so we had to do this to keep it backwards compatible
    this.menuButtons = (this.menuButtons || []).map((b: any) => {
      for (let i = 0; i <= buttonCount; i++) {
        const buttonGuidId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'm.' + i + '.GuidId');
        if (GuidUtils.isEqual(buttonGuidId, b.guidId)) {
          const buttonResourceId = RuntimeLayoutUtils.parseRV(this.layoutControl, 'm.' + i + '.ResourceId');
          if (buttonResourceId) {
            b.icon = PlatformResource.resourceMap[buttonResourceId];
          }
        } else if (GuidUtils.clean(b.guidId) === '00000000000000000000000000000000') {
          b.icon = PlatformResource.resourceMap[1];
        }
      }
      return b;
    });

    this.mobileEngineLayoutState = this.appService.getMobileEngineLayoutState();
    this.refreshButtonsWithMobileEngineLayoutState();
    this.cdr.markForCheck();
  }

  private refreshButtonsWithMobileEngineLayoutState() {
    const windowAny: any = window;
    if (windowAny.mobileEngine?.operationalMode === 'online') return;

    // for each button, check if it should be enabled or disabled according to hybrid state requirements and such
    this.menuButtons = (this.menuButtons || []).map((b: any) => {
      // b.hybridDataSynchronizationRequirement = 0 | 1 (None | Ready)
      b.disabled = !this.mobileEngineLayoutState
      || (b.hybridDataSynchronizationRequirement === 1 && this.mobileEngineLayoutState.hybridDataSyncronizationState !== HybridDataSyncronizationState.Ready)
      || (b.supportHybridState !== 2 && !this.mobileEngineLayoutState.online && this.mobileEngineLayoutState.hybridState === HybridState.Offline)
      || (b.hybridEventQueueSecurityLevel > 0 && !GuidUtils.isNullOrEmpty(b.hybridEventQueueGuidId) && b.hybridEventQueueMaxEvents != null && b.hybridEventQueueMaxEvents < ((b.hybridEventQueueSecurityLevel === HybridEventQueueSecurityLevel.FlowSecured || b.hybridEventQueueSecurityLevel === HybridEventQueueSecurityLevel.FlowFallbackSecured) ? (this.mobileEngineLayoutState.flowQueueCount[b.hybridEventQueueGuidId] || 0) : (this.mobileEngineLayoutState.queueCount || 0)));

      return b;
    });
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    return null;
  }

  backButtonOverride(): boolean {
    if (!this.menuDefinition) return false;
    if (this.menuButtons === this.menuDefinition.items) return false;

    this.numOfCols = this.menuDefinition.cols;
    this.numOfScreenRows = this.menuDefinition.numOfScreenRows;
    this.menuButtons = this.menuDefinition.items;

    this.layoutScreen.backButton = false;
    this.layoutScreenChange.emit(this.layoutScreen);
    this.cdr.markForCheck();
    return true;
  }

  getNgForArray(n: number): number[] {
    return [...Array.from(Array(n).keys())];
  }

  buttonClick(item: any) {
    this.vibrationService.vibrate();

    if (item.isGroup) {
      this.numOfCols = item.cols || 2;
      this.numOfScreenRows = item.numOfScreenRows;
      this.menuButtons = [...item.items];

      this.layoutScreen.backButton = true;
      this.layoutScreenChange.emit(this.layoutScreen);
      this.cdr.markForCheck();
    } else {
      const platformObjectType = GuidUtils.clean(item.guidId) === '00000000000000000000000000000000' ?
        RuntimeLayoutEventPlatformObjectType.LogOff :
        RuntimeLayoutEventPlatformObjectType.MainMenu;
      this.triggerEvent.emit({
        platformObjectType: platformObjectType,
        platformObjectGuidId: GuidUtils.clean(item.guidId),
      });
    }
  }

}

