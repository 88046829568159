<div class="height-100 flex-col center gap-2 middle">
  <h2 style="padding-left: 4px;">
    {{ 'Synchronizing...' | translate }}
  </h2>

  <ng-progress style="width: 100%;"
    [min]="5"
    [flat]="true"
    [relative]="true"
    [spinner]="true"
    [trickleSpeed]="500">
  </ng-progress>
</div>