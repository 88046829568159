import { NgModule } from '@angular/core';
import { NgProgressbar } from 'ngx-progressbar';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoadPopover } from './load.popover';



@NgModule({
    imports: [
        SharedModule,
        NgProgressbar,
    ],
    declarations: [
        LoadPopover,
    ]
})
export class LoadPopoverModule { }
