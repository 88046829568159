
export enum HybridState {
	Unknown = 0,
	Online = 1,
	Offline = 2,
}

export enum HybridDataSyncronizationState {
  Unknown = 0,
  Ready = 1,
  OutOfSync = 2,
  Synchronizing = 3,
  Error = 10,
}

export enum HybridDataStateSharedType {
  None = 0,
  DeviceSolution = 1,
  Site = 2,
  Device = 3,
}

export enum HybridEventQueueSecurityLevel {
  Normal = 0,
  FlowSecured = 1,
  FlowFallbackSecured = 2,
  GlobalSecured = 3,
  GlobalFallbackSecured = 4,
}

export class LayoutState {
  engineReady: boolean;
  hybridReady: boolean;
  hybridState: HybridState;
  hybridDataSyncronizationState: HybridDataSyncronizationState;
  hybridDataStateSyncronizationState: { [key: number]: HybridDataSyncronizationState }; // key is HybridDataStateSharedType
  online: boolean;
  queueCount: number;
  flowQueueCount: { [key: string]: number };
}