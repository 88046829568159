import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { NgProgressbar } from 'ngx-progressbar';
import { VibrationService } from 'src/app/shared/services';





@Component({
  selector: 'lc-load-popover',
  templateUrl: 'load.popover.html',
  styleUrls: ['./load.popover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadPopover implements AfterViewInit {

  @ViewChild(NgProgressbar, { static: true }) progressBar: NgProgressbar;

  text: string;
  private value: number;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private vibrationService: VibrationService,
  ) {
    this.text = this.navParams.get('text');
    this.value = this.navParams.get('value');

    this.vibrationService.vibrate();
  }

  ngAfterViewInit() {
    this.progressBar.start();
    this.setValue(this.value);
  }

  dismiss() {
    this.popoverCtrl.dismiss();
  }

  setValue(value: number) {
    this.value = value;

    if (this.value) {
      this.progressBar.set(this.value);
    }
  }

}
