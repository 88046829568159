import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { RuntimeLayoutUtils } from 'src/app/shared/models/runtime-layout/runtime-layout.utils';
import { ControlBaseComponent } from '../base/control-base.component';
import { SharedModule } from 'src/app/shared/shared.module';
import SignaturePad from 'signature_pad';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'lc-control-sog1',
  templateUrl: 'control-sog1.component.html',
  styleUrls: ['./control-sog1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlSog1Component extends ControlBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('signaturePadCanvas', { static: true }) signaturePadCanvas: any;

  agreementText: string;
  b64Image: string;
  canvasWidth: number;
  canvasHeight: number;
  helpText: string;
  required: boolean;
  signaturePad: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    injector: Injector,
  ) {
    super(injector);

    this.drawStart = this.drawStart.bind(this);
    this.drawStop = this.drawStop.bind(this);
  }

  ngOnInit(): void {
    if (!this.layoutScreen) return;

    this.canvasWidth = this.el.nativeElement.clientWidth - 2;
    this.canvasHeight = this.el.nativeElement.clientHeight - 100;

    this.signaturePad = new SignaturePad(this.signaturePadCanvas.nativeElement, {
      dotSize: 1,
      minWidth: 1, // min thickness
      maxWidth: 2, // max thickness
      // backgroundColor: 'white', // don't use background here as it makes the resulting image unecessarily big
      // penColor: 'black'
    });
    this.signaturePad.addEventListener('beginStroke', this.drawStart);
    this.signaturePad.addEventListener('endStroke', this.drawStop);

    this.agreementText = RuntimeLayoutUtils.parseRV(this.layoutControl, 'BackgroundAgreementText');
    this.helpText = RuntimeLayoutUtils.parseRV(this.layoutControl, 'BackgroundHelpText', this.translateService.instant('Signature goes here...'));
    this.required = RuntimeLayoutUtils.parseRV(this.layoutControl, 'SoGRequired', false);
    if (!this.required) return;

    this.updateForwardButton();
  }

  ngAfterViewInit() {
    // this should be required to adjust to diff DPIs...but I couldn't really figure out how to use it.
    // this.signaturePad.resizeCanvas();
  }

  private updateForwardButton() {
    this.layoutScreen.forwardButton = !!this.b64Image;
    this.layoutScreenChange.emit(this.layoutScreen);
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();
    if (this.b64Image) {
      const b64 = this.b64Image.split(';base64,')[1];
      const mimeType = (this.b64Image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || [''])[0];
      context.set('SoG', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(b64),
        valueTypeId: RuntimeLayoutValueType.String
      }));
      context.set('SoGEncoding', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(mimeType),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }

    context.set('SoGCaptured', Object.assign(new RuntimeLayoutValue(), {
      valueJson: JSON.stringify(!!this.b64Image),
      valueTypeId: RuntimeLayoutValueType.Bool
    }));

    if (RuntimeLayoutUtils.parseRV(this.layoutControl, 'EventGps')) {
      context.set('EventGps', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      }));
    }
    return context;
  }

  refreshCanvas() {
    this.canvasWidth = this.el.nativeElement.clientWidth - 2;
    this.canvasHeight = this.el.nativeElement.clientHeight - 100;
    this.deleteSignature();
  }

  drawStart() {

  }

  drawStop() {
    this.b64Image = this.getCroppedSignatureDataURL();
    this.updateForwardButton();
    this.cdr.markForCheck();
  }

  deleteSignature() {
    this.b64Image = undefined;
    this.signaturePad.clear();
    this.updateForwardButton();
    this.cdr.markForCheck();
  }

  private getCroppedSignatureDataURL() {
    const canvas: HTMLCanvasElement = document.getElementsByTagName('canvas')[0];
    const croppedCanvas: HTMLCanvasElement = document.createElement('canvas');
    const croppedCtx: CanvasRenderingContext2D = croppedCanvas.getContext('2d');

    croppedCanvas.width = canvas.width;
    croppedCanvas.height = canvas.height;
    croppedCtx.drawImage(canvas, 0, 0);

    let w = croppedCanvas.width;
    let h = croppedCanvas.height;
    const pix = { x:[], y:[] };
    const imageData = croppedCtx.getImageData(0, 0, w, h);
    let index = 0;

    for (let y = 0; y < h; y++) {
      for (let x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index+3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }

    pix.x.sort((a,b) => a-b);
    pix.y.sort((a,b) => a-b);
    const n = pix.x.length-1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL();
  }

}

