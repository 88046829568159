import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Observable, Observer, from, of, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfirmPopover } from 'src/app/popovers';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DictString, LanguagesResponse, Settings } from 'src/app/shared/models';
import { DeviceEnrollment3 } from 'src/app/shared/models/memorypack/DeviceEnrollment3';
import { RuntimeLayoutSetting } from 'src/app/shared/models/memorypack/RuntimeLayoutSetting';
import { RuntimeLayoutSettingGroup } from 'src/app/shared/models/memorypack/RuntimeLayoutSettingGroup';
import { RuntimeLayoutSettingGroupSetType } from 'src/app/shared/models/memorypack/RuntimeLayoutSettingGroupSetType';
import { RuntimeLayoutSettingType } from 'src/app/shared/models/memorypack/RuntimeLayoutSettingType';
import { AppService, BusyService, LocalSettingsService, NotificationService, TextService, VibrationService } from 'src/app/shared/services';
import { TranslateService } from 'src/app/shared/services/app';
import { SetSettingsService } from 'src/app/shared/services/protocol/set-settings.service';
import { ThemeService } from 'src/app/shared/services/theme/theme.service';
import { BrowserUtils, DateUtils, LogUtils } from 'src/app/shared/utils';

@Component({
  selector: 'lc-settings-modal',
  templateUrl: 'settings.modal.html',
  styleUrls: ['./settings.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsModal extends BaseComponent implements OnInit {

  private readonly settingGroupLanguageName: string = 'Language';

  private readonly settingGroupFontSizeName: string = 'FontSize';
  private readonly settingGroupFullScreenName: string = 'FullScreen';
  private readonly settingGroupKeyboardName: string = 'KeyboardType';
  private readonly settingGroupScreenOrientationName: string = 'ScreenOrientation';
  private readonly settingGroupThemeName: string = 'Style';
  private readonly settingGroupVibrationName: string = 'Vibration';
  private readonly settingGroupPath: string = 'Platform/';

  deviceEnrollment: DeviceEnrollment3;
  fontSizeIsDirty: boolean;
  fullScreenIsDirty: boolean;
  isAndroid: boolean;
  isDeviceApp: boolean;
  keyboardIsDirty: boolean;
  languageOriginalGuidId: string;
  languageGuidId: string;
  languageOptions: any[];
  languageIsDirty: boolean;
  languagesMap: DictString<string>;
  operationalMode: 'online' | 'hybrid';
  operationalModeOriginal: 'online' | 'hybrid';
  operationalModeIsDirty: boolean;
  themeIsDirty: boolean;
  screenOrientationIsDirty: boolean;
  vibrationIsDirty: boolean;

  settings: Settings;



  constructor(
    private appService: AppService,
    private busyService: BusyService,
    private cdr: ChangeDetectorRef,
    private localSettingsService: LocalSettingsService,
    private modalCtrl: ModalController,
    private notificationService: NotificationService,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private textService: TextService,
    private translateService: TranslateService,
    private setSettingsService: SetSettingsService,
    private themeService: ThemeService,
    private vibrationService: VibrationService,
  ) {
    super();

    this.subscriptions.push(
      this.appService.listenToBackButtonClick()
      .subscribe(() => {
        this.dismiss();
      })
    );
  }

  ngOnInit() {
    this.isAndroid = this.platform.is('android');
    this.isDeviceApp = BrowserUtils.isDeviceApp();

    this.languageOptions = [];
    this.settings = JSON.parse(JSON.stringify(this.localSettingsService.get()));
    this.languageIsDirty = false;
    this.keyboardIsDirty = false;
    this.fontSizeIsDirty = false;
    this.fullScreenIsDirty = false;
    this.operationalModeIsDirty = false;
    this.screenOrientationIsDirty = false;
    this.themeIsDirty = false;
    this.vibrationIsDirty = false;

    const windowAny: any = window;
    this.operationalMode = windowAny.mobileEngine?.operationalMode;
    this.operationalModeOriginal = this.operationalMode;

    this.cdr.markForCheck();

    this.deviceEnrollment = this.appService.getDeviceEnrollment();
    const request = this.deviceEnrollment ? this.textService.listLanguages(this.deviceEnrollment.enrollmentGuidId) : of(null);
    request.subscribe((lr: LanguagesResponse) => {
      if (!lr) return;

      this.languagesMap = lr.languages;
      for (const key of Object.keys(this.languagesMap)) {
        this.languageOptions.push({ value: key, label: this.languagesMap[key] })
      }

      this.cdr.markForCheck();

      setTimeout(() => {
        this.languageGuidId = this.textService.languageGuidId || lr.defaultLanguageGuidId;
        this.languageOriginalGuidId = this.languageGuidId;

        this.cdr.markForCheck();
      }, 10);
    }, (error: any) => {
      LogUtils.error('Failed to listLanguages()', error);
    });
  }

  dismiss() {
    this.vibrationService.vibrate();

    this.modalCtrl.dismiss();
  }

  submit() {
    this.vibrationService.vibrate();

    this.themeService.setTheme(
      this.settings.theme,
      this.settings.fontSize,
    );

    if (this.keyboardIsDirty) this.settings.keyboardChangedByUser = true;

    if (this.deviceEnrollment) {
      if (this.languageIsDirty || this.operationalModeIsDirty) {
        this.showConfirmPopover()
        .subscribe((result: boolean) => {
          if (result) {
            this.updateSettingsRemotely();
          } else {
            this.languageGuidId = this.languageOriginalGuidId;
            this.languageIsDirty = false;
            this.operationalMode = this.operationalModeOriginal;
            this.operationalModeIsDirty = false;

            this.cdr.markForCheck();
          }
        });
      } else {
        this.updateSettingsRemotely();
      }
    } else {
      LogUtils.log(`Saving settings locally only as there's no DeviceEnrollment and no active server connection.`);
      this.localSettingsService.set(this.settings)
      .subscribe(() => {
        this.appService.setFullScreen(this.settings.fullScreen);
        this.appService.setScreenOrientation(this.settings.screenOrientation);
        this.modalCtrl.dismiss();

        this.cdr.markForCheck();
      });
    }
  }

  private showConfirmPopover(): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      from(this.popoverCtrl.create({
        component: ConfirmPopover,
        componentProps: {
          title: this.translateService.instant('Settings'),
          text: this.translateService.instant('Changing these settings will force a device restart...ok?'),
        },
        cssClass: `popover-confirm`,
        backdropDismiss: false,
        showBackdrop: true,
      }))
      .subscribe((confirmPopover: HTMLIonPopoverElement) => {
        from(confirmPopover.onDidDismiss())
        .subscribe((result: OverlayEventDetail<boolean>) => {
          observer.next(result.data);
          observer.complete();
        });
        confirmPopover.present();
      });
    });
  }

  private updateSettingsRemotely() {
    this.busyService.setBusy(
      true,
      this.translateService.instant('Updating settings remotely...')
    );
    this.cdr.markForCheck();

    const settingGroupFontSize = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupFontSizeName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupFontSizeName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.settings.fontSize
        }),
      ]
    });
    const settingGroupFullScreen = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupFullScreenName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupFullScreenName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: (this.settings.fullScreen || false).toString()
        }),
      ]
    });
    const settingGroupKeyboard = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupKeyboardName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupKeyboardName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.settings.keyboard?.toString(),
        }),
      ]
    });
    const settingGroupLanguage = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupLanguageName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupLanguageName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.languageGuidId
        }),
      ]
    });
    const settingGroupScreenOrientation = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupScreenOrientationName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupScreenOrientationName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.settings.screenOrientation
        }),
      ]
    });
    const settingGroupTheme = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupThemeName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupThemeName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.settings.theme
        }),
      ]
    });
    const settingGroupVibration = Object.assign(new RuntimeLayoutSettingGroup(), {
      active: true,
      name: this.settingGroupVibrationName,
      path: this.settingGroupPath,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Software,
      settings: [
        Object.assign(new RuntimeLayoutSetting(), {
          name: this.settingGroupVibrationName,
          settingType: RuntimeLayoutSettingType.Setting,
          value: (this.settings.vibration || false).toString()
        }),
      ]
    });

    const requests = [];
    if (this.operationalModeIsDirty) {
      this.settings.operationalMode = this.operationalMode;
    }
    // TODO: the local settings may not make sense here since it comes from runtimeLayoutSnapshot and gets updated there...
    requests.push(this.localSettingsService.set(this.settings));
    if (this.languageIsDirty) {
      requests.push(
        this.textService.setLanguage(
          this.deviceEnrollment.enrollmentGuidId,
          this.languageGuidId,
          this.languagesMap?.[this.languageGuidId],
        )
      );
      requests.push(this.setSettingsService.trigger(settingGroupLanguage));
    }
    if (this.fontSizeIsDirty) {
      requests.push(this.setSettingsService.trigger(settingGroupFontSize));
    }
    if (this.fullScreenIsDirty) {
      requests.push(this.setSettingsService.trigger(settingGroupFullScreen));
    }
    if (this.keyboardIsDirty) {
      requests.push(this.setSettingsService.trigger(settingGroupKeyboard));
    }
    if (this.screenOrientationIsDirty) {
      requests.push(this.setSettingsService.trigger(settingGroupScreenOrientation));
    }
    if (this.themeIsDirty) {
      requests.push(this.setSettingsService.trigger(settingGroupTheme));
    }
    if (this.vibrationIsDirty) {
      requests.push(this.setSettingsService.trigger(settingGroupVibration));
    }

    zip(...requests)
    .pipe(
      mergeMap(() => {
        if (this.keyboardIsDirty) {
          this.appService.focusActiveControl();
        }

        if (this.operationalModeIsDirty) {
          const windowAny: any = window;
          windowAny.mobileEngine.operationalMode = this.operationalMode;
          this.appService.initMobileEngineAndThenInitWsAndAuthenticate(this.deviceEnrollment);
          return of(null);
        } else if (this.languageIsDirty) {
          return this.appService.initWsAndAuthenticate(this.deviceEnrollment);
        } else {
          this.appService.setFullScreen(this.settings.fullScreen);
          this.appService.setScreenOrientation(this.settings.screenOrientation);
          return of(null);
        }
      })
    )
    .subscribe(() => {
      this.busyService.setBusy(false);
      this.modalCtrl.dismiss();
    }, (error: any) => {
      this.busyService.setBusy(false);
      this.notificationService.showAlert(
        this.translateService.instant('Error'),
        error,
      ).subscribe();
    });
  }

}
