import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { mergeMap } from 'rxjs/operators';
import { Settings } from 'src/app/shared/models';
import { AppService, BasePlugin, BusyService, DebugService, LocalSettingsService, PluginService, PluginType, VibrationService } from 'src/app/shared/services';
import { TranslateService } from 'src/app/shared/services/app';
import { OpsStatsService } from 'src/app/shared/services/app/ops-stats.service';
import { LayoutDebugMailService } from 'src/app/shared/services/protocol/layout-debug-mail.service';
import { LogUtils } from 'src/app/shared/utils';


@Component({
  selector: 'lc-debug-modal',
  templateUrl: 'debug.modal.html',
  styleUrls: ['./debug.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugModal implements OnInit {

  appStatus: any;
  btAllowed: boolean;
  btStatus: any;
  cipherLabAllowed: boolean;
  cipherLabStatus: boolean;
  honeywellAllowed: boolean;
  honeywellStatus: boolean;
  nfcAllowed: boolean;
  nfcStatus: any;
  opticonAllowed: boolean;
  opticonStatus: any;
  pointMobileAllowed: boolean;
  pointMobileStatus: any;
  satoAllowed: boolean;
  satoStatus: any;
  zebraAllowed: boolean;
  zebraStatus: any;

  settings: Settings;

  isSendToDevButtonDisabled = false;

  constructor(
    private appService: AppService,
    private busyService: BusyService,
    private cdr: ChangeDetectorRef,
    private debugMailService: LayoutDebugMailService,
    private debugService: DebugService,
    private localSettingsService: LocalSettingsService,
    private modalCtrl: ModalController,
    private ngZone: NgZone,
    private opsStatsService: OpsStatsService,
    private pluginService: PluginService,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {

  }

  ngOnInit() {
    this.appStatus = LogUtils.getLogArray();

    this.settings = this.localSettingsService.get();

    const btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);
    const cipherLabPlugin = this.pluginService.getInstance(PluginType.CipherLab);
    const honeywellPlugin = this.pluginService.getInstance(PluginType.Honeywell);
    const nfcPlugin = this.pluginService.getInstance(PluginType.NFC);
    const opticonPlugin = this.pluginService.getInstance(PluginType.Opticon);
    const pointMobilePlugin = this.pluginService.getInstance(PluginType.PointMobile);
    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    const zebraPlugin = this.pluginService.getInstance(PluginType.Zebra);

    this.btAllowed = btPlugin.isPluginAllowed();
    this.cipherLabAllowed = cipherLabPlugin.isPluginAllowed();
    this.honeywellAllowed = honeywellPlugin.isPluginAllowed();
    this.nfcAllowed = nfcPlugin.isPluginAllowed();
    this.opticonAllowed = opticonPlugin.isPluginAllowed();
    this.pointMobileAllowed = pointMobilePlugin.isPluginAllowed();
    this.satoAllowed = satoPlugin.isPluginAllowed();
    this.zebraAllowed = zebraPlugin.isPluginAllowed();

    this.btStatus = { btClassic: null, ble: null };
    this.cipherLabStatus = null;
    this.honeywellStatus = null;
    this.nfcStatus = null;
    this.opticonStatus = null;
    this.pointMobileStatus = null;
    this.zebraStatus = null;
    this.getPluginStatus(btPlugin, 'btStatus');
    this.getPluginStatus(cipherLabPlugin, 'cipherLabStatus');
    this.getPluginStatus(honeywellPlugin, 'honeywellStatus');
    this.getPluginStatus(nfcPlugin, 'nfcStatus');
    this.getPluginStatus(opticonPlugin, 'opticonStatus');
    this.getPluginStatus(pointMobilePlugin, 'pointMobileStatus');
    this.getPluginStatus(satoPlugin, 'satoStatus');
    this.getPluginStatus(zebraPlugin, 'zebraStatus');
  }

  private getPluginStatus(plugin: BasePlugin, statusKey: string) {
    plugin.status()
    .subscribe((status: any) => {
      this.ngZone.run(() => {
        if (status) {
          this[statusKey] = status;
        } else {
          this[statusKey] = this.translateService.instant('-- Status Not Available --');
        }
      });
      this.cdr.markForCheck();
    });
  }

  dismiss() {
    this.vibrationService.vibrate();

    this.modalCtrl.dismiss();
  }

  runDeviceDebugChange(ev: CustomEvent) {
    this.busyService.setBusy(
      true,
      this.translateService.instant('Setting Debug Mode...')
    );
    this.cdr.markForCheck();

    this.settings.runDeviceDebug = ev.detail.checked;
    this.localSettingsService.set(this.settings)
    .pipe(
      mergeMap(() => {
        return this.appService.initWsAndAuthenticate(this.appService.getDeviceEnrollment())
      })
    )
    .subscribe(() => {
      this.busyService.setBusy(false);
      this.cdr.markForCheck();
    }, (error: any) => {
      this.busyService.setBusy(false);
      console.warn(error);
      this.cdr.markForCheck();
    });
  }

  sendAppStatus() {
    if (this.isSendToDevButtonDisabled) return;

    this.isSendToDevButtonDisabled = true;
    setTimeout(() => {
      try {
        Sentry.setExtra('deviceEnrollment', this.appService.getDeviceEnrollment());
        Sentry.setExtra('layoutSnapshot', this.appService.getLayoutSnapshot());
        Sentry.setExtra('opsStats', this.opsStatsService.getStatsForAllTypes());
        Sentry.setExtra('settings', this.settings);
        Sentry.setExtra('log', LogUtils.getLogArray());
        Sentry.setExtra('btStatus', this.btStatus);
        Sentry.setExtra('cipherLabStatus', this.cipherLabStatus);
        Sentry.setExtra('honeywellStatus', this.honeywellStatus);
        Sentry.setExtra('nfcStatus', this.nfcStatus);
        Sentry.setExtra('pointMobileStatus', this.pointMobileStatus);
        Sentry.setExtra('satoStatus', this.satoStatus);
        Sentry.setExtra('zebraStatus', this.zebraStatus);

        Sentry.captureException(new Error('Logs sent to developer'));
      } catch (error) {
        LogUtils.error('sendAppStatus() sentry:', error);
      }

      try {
        this.debugService.sendAppStatus(this.btStatus, this.cipherLabStatus, this.nfcStatus, this.satoStatus, this.zebraStatus)
        .subscribe(() => {

        }, (error: any) => {
          LogUtils.error('sendAppStatus() zappier1:', error);
        });
      } catch (error) {
        LogUtils.error('sendAppStatus() zappier2:', error);
      }

      try {
        this.debugMailService.trigger(
          'LogicCenter Mobile Error Logs',
          JSON.stringify({
            deviceEnrollment: this.appService.getDeviceEnrollment(),
            // layoutSnapshot: this.appService.getLayoutSnapshot(),
            opsStats: this.opsStatsService.getStatsForAllTypes(),
            settings: this.settings,
            log: LogUtils.getLogArray(),
            btStatus: this.btStatus,
            cipherLabStatus: this.cipherLabStatus,
            honeywellStatus: this.honeywellStatus,
            nfcStatus: this.nfcStatus,
            pointMobileStatus: this.pointMobileStatus,
            satoStatus: this.satoStatus,
            zebraStatus: this.zebraStatus,
          }, null, 1),
        )
        .subscribe((response: any) => {
          alert(this.translateService.instant('Logs sent to developer.'));
          setTimeout(() => {
            this.isSendToDevButtonDisabled = false;
            this.cdr.markForCheck();
          }, 10 * 1000);
        }, (error: any) => {
          LogUtils.error('sendAppStatus() debugMail1:', error);
        });
      } catch (error) {
        LogUtils.error('sendAppStatus() debugMail2:', error);
      }
    }, 1000);
  }

}
