<ion-header>
  <ion-toolbar>
    <ion-title>
        {{ 'Settings' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ion-button full
        (click)="submit()">
        <ion-icon name="save"></ion-icon>
      </ion-button>
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="wrapper">
    <ion-list *ngIf="settings"
      class="flex-1">

      <ion-item *ngIf="deviceEnrollment">
        <ion-select
          interface="popover"
          [label]="'Language' | translate"
          [(ngModel)]="languageGuidId"
          (ngModelChange)="languageIsDirty = true">
          <ion-select-option *ngFor="let language of languageOptions"
            [value]="language.value">
            {{ language.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select
          interface="popover"
          [label]="'Operational Mode' | translate"
          [(ngModel)]="operationalMode"
          (ngModelChange)="operationalModeIsDirty = true">
          <ion-select-option value="online">
            {{ 'Online' | translate }}
          </ion-select-option>
          <ion-select-option value="hybrid">
            {{ 'Hybrid' | translate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select
          interface="popover"
          [label]="'Theme' | translate"
          [(ngModel)]="settings.theme"
          (ngModelChange)="themeIsDirty = true">
          <ion-select-option value="dark">
            {{ 'Dark' | translate }}
          </ion-select-option>
          <ion-select-option value="light">
            {{ 'Light' | translate }}
          </ion-select-option>
          <ion-select-option value="system">
            {{ 'System' | translate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select
          interface="popover"
          [label]="'Font Size' | translate"
          [(ngModel)]="settings.fontSize"
          (ngModelChange)="fontSizeIsDirty = true">
          <ion-select-option value="smaller">
            {{ 'Smaller' | translate }}
          </ion-select-option>
          <ion-select-option value="small">
            {{ 'Small' | translate }}
          </ion-select-option>
          <ion-select-option value="medium">
            {{ 'Medium' | translate }}
          </ion-select-option>
          <ion-select-option value="big">
            {{ 'Big' | translate }}
          </ion-select-option>
          <ion-select-option value="huge">
            {{ 'Huge' | translate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="isDeviceApp && isAndroid">
        <ion-toggle
          slot="end"
          [(ngModel)]="settings.fullScreen"
          (ngModelChange)="fullScreenIsDirty = true">
          {{ 'FullScreen' | translate }}
        </ion-toggle>
      </ion-item>

      <ion-item *ngIf="isDeviceApp">
        <ion-select
          interface="popover"
          [label]="'ScreenOrientation' | translate"
          [(ngModel)]="settings.screenOrientation"
          (ngModelChange)="screenOrientationIsDirty = true">
          <ion-select-option value="portrait-primary">
            {{ 'Portrait' | translate }}
          </ion-select-option>
          <ion-select-option value="landscape">
            {{ 'Landscape' | translate }}
          </ion-select-option>
          <ion-select-option value="any">
            {{ 'Auto' | translate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select
          interface="popover"
          [label]="'Keyboard' | translate"
          [(ngModel)]="settings.keyboard"
          (ngModelChange)="keyboardIsDirty = true">
          <ion-select-option [value]="0">
            {{ 'LogicCenter' | translate }}
          </ion-select-option>
          <ion-select-option [value]="1">
            {{ 'Native' | translate }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="isDeviceApp">
        <ion-label>
          {{ 'Vibration' | translate }}
        </ion-label>
        <ion-toggle
          slot="end"
          [(ngModel)]="settings.vibration"
          (ngModelChange)="vibrationIsDirty = true">
          {{ 'Vibration' | translate }}
        </ion-toggle>
      </ion-item>

    </ion-list>

  </div>

</ion-content>
