import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { RequireOnlineHybridStateResult } from 'src/app/shared/models/manager/require-online-hybrid-state-result.model';
import { RuntimeLayoutEventContext } from 'src/app/shared/models/memorypack/RuntimeLayoutEventContext';
import { RuntimeLayoutEventPlatformObjectType } from 'src/app/shared/models/memorypack/RuntimeLayoutEventPlatformObjectType';
import { RuntimeLayoutValue } from 'src/app/shared/models/memorypack/RuntimeLayoutValue';
import { RuntimeLayoutValueType } from 'src/app/shared/models/runtime-layout/runtime-layout-value-type.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { LogUtils } from 'src/app/shared/utils';
import { ControlBaseComponent } from '../base/control-base.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'lc-control-require-online-hybrid-state',
  templateUrl: 'control-require-online-hybrid-state.component.html',
  styleUrls: ['./control-require-online-hybrid-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlRequireOnlineHybridStateComponent extends ControlBaseComponent implements OnInit, OnDestroy {

  theme: string;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    const windowAny: any = window;
    from(windowAny.mobileEngine.instance.invokeMethodAsync('HybridDeviceControlRequireOnlineHybridStateAsync', 3))
    .subscribe((result: RequireOnlineHybridStateResult) => {
      if (!result) return;

      LogUtils.log(result.debugText);
      let portName = 'Error';
      if (result.success) {
        portName = result.resultPort;
      } else if (result.timeout) {
        portName = 'Timeout';
      } else {
        portName = 'Error';
      }

      const eventContextValues = new Map<string, RuntimeLayoutValue | null>();
      eventContextValues.set('PortName', Object.assign(new RuntimeLayoutValue(), {
        valueJson: JSON.stringify(portName),
        valueTypeId: RuntimeLayoutValueType.String,
      }));
      this.triggerEvent.emit({
        eventContext: Object.assign(new RuntimeLayoutEventContext(), { values: eventContextValues }),
        platformObjectType: RuntimeLayoutEventPlatformObjectType.None,
      });
    });
    this.cdr.markForCheck();
  }

  getControlContext(): Map<string, RuntimeLayoutValue | null> | null {
    const context = new Map<string, RuntimeLayoutValue | null>();

    return context;
  }

}

