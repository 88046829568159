<div class="popover-grid-container">
  <h2>
    {{ 'Loading' | translate }}
  </h2>

  <p class="text">
    {{ text }}
  </p>

  <br>
</div>

<ng-progress style="width: 100%;"
  [min]="5"
  [flat]="true"
  [relative]="true"
  [spinner]="true"
  [trickleSpeed]="500">
</ng-progress>
